import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../store/auth-context';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import ExcerciseCard from '../components/estudiante/ExcerciseCard';

const StudentExerciseView = () => {
    const [data, setData] = useState([])

    const history = useHistory();

    const authCtx = useContext(AuthContext)
    const token = authCtx.token

    const refresh = authCtx.refreshEdit

    useEffect(() => {
        axios.get('https://www.api.simplifiedenglishacademy.com.uy/exercises', {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then((res) => {
                setData(res.data.response)
            })
            .catch((error) => {
                console.error(error)
            })

    }, [refresh]);

    return (
        <div style={{display:'flex', justifyContent: 'center', flexDirection: 'row', flexWrap :"wrap"}}>

            {data.map((el, key) => (
                <ExcerciseCard key={key} dataCard={el} />
            ))}
        </div>
    )
}

export default StudentExerciseView
