import React, {useState} from "react";

const AuthContext = React.createContext({
    token:'',
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {},
});

export const AuthContextProvider = (props) =>{
    const initialToken = localStorage.getItem('token')
    const initialRol = localStorage.getItem('rol')
    const initialName = localStorage.getItem('userName')
    const initialId = localStorage.getItem('userId')
    const [token, setToken] = useState(initialToken)
    const [rol, setRol] = useState(initialRol)
    const [userName, setName] = useState(initialName)
    const [userId, setUserId] = useState(initialId)
    const [cambio, setCambio] = useState(true)

    const userIsLoggedIn = !!token;
    const userIsAdmin = rol == 'Admin';
    const loginHandler = (data) => {
        setToken(data.token)
        setRol(data.response.rol)
        localStorage.setItem('token',data.token)
        localStorage.setItem('rol',data.response.rol)
        localStorage.setItem('userName',data.response.name)
        localStorage.setItem('userId',data.response.userId)
        setToken(data.token)
        setName(data.response.name)
        setUserId(data.response.userId)
    };
    const logoutHandler = () => {
        setToken(null)
        setRol(null)
        setName(null)
        setUserId(null)
        localStorage.removeItem('token')
        localStorage.removeItem('rol')
        localStorage.removeItem('userName')
        localStorage.removeItem('userId')
    };

  const[refreshEdit, setRefreshEdit] = useState(1);    

    const contextValue = {
        token: token,
        rol: rol,
        name: userName,
        id: userId,
        isLoggedIn: userIsLoggedIn,
        isAdmin: userIsAdmin,
        login: loginHandler,
        logout: logoutHandler,
        refreshEdit: refreshEdit,
        setRefreshEdit: setRefreshEdit
    }

      return <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext;