import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useState, useEffect, useContext } from 'react';
import styles from '../../styles/ejercicios/Ejercicios.module.css'
import axios from 'axios';
import AuthContext from '../../store/auth-context.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UploadFile from '../UploadFile';

export default function ExerciseEdit({ datos, setDatos, guardar, setGuardar, setActiveStep }) {

  const [tiposTitulos, setTiposTitulos] = useState({
    arrows: ["Match the words from the left column with those in the write to make suitable sentences."],
    cloze: ["Use of English - Key Word Transformations. Complete the second sentence so it has a similar meaning to the first sentence, using the word given. Do not change the word given."],
    complete: ["Complete the gap with a suitable word from the word in brackets"],
    select: ["Multiple Choice Listening.", "Sentence Completion Listening.", "Multiple Matching Listening.", "Multiple Choice Reading.", "Gapped Text Reading.", "Multiple Matching Reading."],
    similary: ["Use of English - Word Formation For questions 1-8, use the word given in capitals at the end of some of the lines to form a new word."],
    text: ["Multiple Choice Listening. For questions 1-8, choose the best answer (A,B,C)", "Sentence Completion Listening. For questions 1-10, complete the sentences with between one and three words.", "Multiple Matching Listening. For questions 1-5, choose from the list A-H what each speaker says.", "Multiple Choice Reading. Read the text. For questions 1-6, choose the correct answer (A,B,C or D)", "Gapped Text Reading. Read the text. Six sentences have been removed. Choose from the sentences A-G the one which fits each gap (1-6)", "Multiple Matching Reading. Read thd article. For questions 1-10, choose from A-E which answer better fits."]
  });

  const [tiposTitulosSelected, setTiposTitulosSelected] = useState("")

  const [editOpen, setEditOpen] = useState(false)
  //variables del navBar
  const [state, setState] = React.useState({ right: false, });
  const [anchor, setAnchor] = React.useState('right');
  //variable que guarda el contenido de titulo, texto, imagen, completar
  const [contenido, setContenido] = useState('')
  //variable para guardar la pista
  const [pista, setPista] = useState('')
  //variable para contar la posicion
  const [id, setId] = useState(0);
  //variable que determina que editor mostrar
  const [mostrar, setMostrar] = useState('')
  //variable que guarda datos de flechas
  const [flechas, setFlechas] = React.useState([]);
  //variable auxiliar de imagen
  const [imagen, setImagen] = useState('')
  //variables de opcion
  const [opcionRespuestas, setOpcionRespuestas] = useState([
    {
      answer: "Respuesta 1",
      value: 0
    },
    {
      answer: "Respuesta 2",
      value: 0
    }
  ]);

  const [opcionPregunta, setOpcionPregunta] = useState("");
  const [opcionPreguntasCantidad, setOpcionPreguntasCantidad] = useState([1, 5]);

  const [flechasCantidad, setFlechasCantidad] = useState([1, 5]);
  //Si esta true abre cuadro de consulta inicial
  const [consultaInicial, setConsultaInicial] = useState(true)
  //Variable que carga los cursos
  const [cursos, setCursos] = useState();

  //PROCESOS GENERALES
  //Carga los cursos a mostrar
  const authCtx = useContext(AuthContext)
  const token = authCtx.token
  const history = useHistory()

  useEffect(() => {
    axios.get('https://www.api.simplifiedenglishacademy.com.uy/course', {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((res) => {
        setCursos(res.data.response)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [authCtx.refreshEdit]);

  //Esto sirve para agregar, titulo, texto, imagen, completar
  let loQueAgrego = { "id": id, "type": "", "clue": pista, "title": tiposTitulosSelected, "content": contenido };

  function agregar(tipo) {
    loQueAgrego.type = tipo;
    if (tipo === "Cloze") {
      loQueAgrego.content = (loQueAgrego.content.split("\n")).join(" (salto) ")
    }
    setDatos(oldArray => [...oldArray, loQueAgrego]);
    setId(id + 1);
    setState({ "right": false })
    setActiveStep(id + 1)
    setPista("")
    setTiposTitulosSelected("")
  }

  //Cuando se hace un cambio en las preguntas de opcion salta esta accion
  const opcionOnChange = (event, key) => {
    if (event.target.name === "preg") {
      setOpcionPregunta(event.target.value);
    } else {
      const id = Number(event.target.id);
      setOpcionRespuestas((prevRespuestas) => {
        const updatedRespuestas = [...prevRespuestas];
        updatedRespuestas[id] = {
          ...updatedRespuestas[id],
          [event.target.name]: event.target.name === "value" ? (event.target.value === "true" ? 1 : 0) : event.target.value
        };
        return updatedRespuestas;
      });
    }
  };
  

  const agregarOpcion = () => {
    const nuevaRespuesta = {
      answer: `Respuesta ${opcionPreguntasCantidad.length + 1}`,
      value: 0
    };
    setOpcionRespuestas((prevRespuestas) => [...prevRespuestas, nuevaRespuesta]);
    setOpcionPreguntasCantidad([...opcionPreguntasCantidad, nuevaRespuesta.answer]);
  };


  const opcionCrear = () => {
    let armar = {};
    armar.id = id;
    armar.type = "Option";
    armar.question = opcionPregunta;
    armar.clue = pista;
    armar.title = tiposTitulosSelected;
    armar.answer = [...opcionRespuestas]; // Convierte el objeto en un array
  
    setDatos((oldArray) => [...oldArray, armar]);
    setId(id + 1);
    setState({ right: false });
    setActiveStep(id + 1);
    setPista("");
    setTiposTitulosSelected("");
  
    setOpcionRespuestas([
      {
        answer: "Respuesta 1",
        value: 0
      },
      {
        answer: "Respuesta 2",
        value: 0
      }
    ]);
  
    setOpcionPreguntasCantidad([1, 5]);
  };

  //reacciona cuando se hace un cambio en los campos flechas
  const flechasOnChange = (event, key) => {
    let id = Number(event.target.id);
    setFlechas(
      oldArray => ({
        ...oldArray,
        [id]: {
          ...oldArray[id],
          [event.target.name]: event.target.value
        }
      }
      )
    )
  }

  const flechasCrear = () => {

    let armar = {};

    armar.id = id;
    armar.type = "Arrows";
    armar.clue = pista;
    armar.title = tiposTitulosSelected
    armar.content = [];

    for (let index = 0; index < flechasCantidad.length; index++) {
      armar.content[index] = flechas[index];
    }
    setDatos(oldArray => [...oldArray, armar]);
    setId(id + 1);
    setState({ "right": false })
    setActiveStep(id + 1)
    setFlechas([]);
    setFlechasCantidad([1, 5])
    setPista("")
    setTiposTitulosSelected("")
  }

  const agregarFlechas = () => {

    let posicion = flechas.length - 1
    setFlechas(oldArray => ({ ...oldArray, [posicion]: { question: 'Agregue opcion', answer: 'Agregue Opcion' } }));
    setFlechasCantidad(
      oldArray => ([...oldArray, "mas"
      ])
    );
  }
  //reacciona cuando se hace un cambio en guardar

  const guardarOnChange = (event, key) => {
    setGuardar({
      ...guardar,
      [event.target.name]: event.target.value
    })
  }

  const handleCallback = (childData) => {
    let imgUrl = "https://drive.google.com/uc?export=view&id=" + childData.id
    setImagen(imgUrl)
  }
  //funcion que se ejecuta cuando se selecciona una imagen
  function agregarImagen(e) {
    // Creamos el objeto de la clase FileReader
    let reader = new FileReader();
    // Leemos el archivo subido y se lo pasamos a nuestro fileReader
    reader.readAsDataURL(e.target.files[0]);
    // Le decimos que cuando este listo ejecute el código interno
    reader.onload = function () {
      setImagen(reader.result)
    };
  }

  //detecta cuando se selecciono una imagen y crea el nuevo elemento
  useEffect(() => {
    if (imagen != "") {
      setDatos(oldArray => [...oldArray, { "id": id, "type": "Image", "content": imagen, "clue": pista }]);
      setId(id + 1);
      setState({ "right": false })
      setActiveStep(id + 1)
      setPista("")
      setTiposTitulosSelected("")
    }
  }, [imagen]);

  //Guarda los datos de guardar como otro elemento

  function GuardarGuardar() {

    if (guardar["title"] === undefined) {
      alert("You must add a title")
    } else if (guardar["description"] === undefined) {
      alert("You must add a description")
    } else if (guardar["courseId"] === undefined) {
      alert("You must add a course")
    } else if (consultaInicial === true) {
      setConsultaInicial(false)
    } else {

      let enviarCurso = { "id": 11, "title": guardar["title"], "description": guardar["description"], "courseId": guardar["courseId"], "components": datos }

      axios.post('https://www.api.simplifiedenglishacademy.com.uy/exercises', enviarCurso, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .then((res) => {
          authCtx.setRefreshEdit(-authCtx.refreshEdit)
          alert("Exercise created successfully!!!")
          history.replace('/exercises')
        })

    }
  }

  //drawer
  function mostrarEnNav(cual) {
    setMostrar(cual);
    setState({ "right": true });
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
    </Box>
  );

  return (
    <>
      <button className={styles.buttonFlotante} onClick={() => setEditOpen(!editOpen)}>{editOpen ? <>{"CLOSE"} </> : <>{"OPEN"}</>}</button>

      {editOpen &&
        <div className={styles.flotante}>
          <React.Fragment key={anchor}>
            <Button onClick={(e) => mostrarEnNav('Text')}>Text</Button><br />
            <Button onClick={(e) => mostrarEnNav('Complete')}>Complete</Button><br />
            <Button onClick={(e) => mostrarEnNav('Image')}>Image</Button><br />
            <Button onClick={(e) => mostrarEnNav('Arrows')}>Arrows</Button><br />
            <Button onClick={(e) => mostrarEnNav('Option')}>Option</Button><br />
            <Button onClick={(e) => mostrarEnNav('Similary')}>Similary</Button><br />
            <Button onClick={(e) => mostrarEnNav('Cloze')}>Cloze</Button><br />
            <Button onClick={(e) => mostrarEnNav('Select')}>Select</Button><br />
            <Button onClick={(e) => mostrarEnNav('Save')}>Save</Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {
                mostrar == "Title" &&
                <div>
                  Title<br />
                  <textarea rows="5" placeholder='content' onChange={event => setContenido(event.target.value)} /><br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <button onClick={(e) => agregar('Title')} >add</button>

                </div>
              }
              {
                mostrar == "Text" &&
                <div>
                  Text<br />
                  <textarea rows="5" placeholder='content' onChange={event => setContenido(event.target.value)} /><br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <select onChange={(event) => setTiposTitulosSelected(event.target.value)}>
                    <option></option>
                    {
                      tiposTitulos.text.map((value) =>
                        <option>{value}</option>
                      )
                    }
                  </select><br />
                  <button onClick={(e) => agregar('Text')} >add</button>
                </div>
              }
              {
                mostrar == "Clue" &&
                <div>
                  Clue<br />
                  <textarea rows="5" placeholder='content' onChange={event => setContenido(event.target.value)} /><br />
                  <button onClick={(e) => agregar('Clue')} >add</button>
                </div>
              }
              {
                mostrar == "Image" &&
                <div>
                  <h5>Select Image</h5><br />
                  <UploadFile parentCallback={handleCallback} />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <div id="preview"></div>
                </div>
              }
              {
                mostrar == "Complete" &&
                <div>
                  Complete<br />
                  <textarea rows="5" placeholder='content' onChange={event => setContenido(event.target.value)} /><br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <select onChange={(event) => setTiposTitulosSelected(event.target.value)}>
                    <option></option>
                    {
                      tiposTitulos.complete.map((value) =>
                        <option>{value}</option>
                      )
                    }
                  </select><br />
                  <button onClick={(e) => agregar('Complete')} >add</button>
                </div>
              }
              {
                mostrar == "Similary" &&
                <div>
                  Similary<br />
                  <textarea rows="5" placeholder='content' onChange={event => setContenido(event.target.value)} /><br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <select onChange={(event) => setTiposTitulosSelected(event.target.value)}>
                    <option></option>
                    {
                      tiposTitulos.similary.map((value) =>
                        <option>{value}</option>
                      )
                    }
                  </select><br />
                  <button onClick={(e) => agregar('Similary')} >Similary</button><br /><br />
                  <h6>La primer palabra entre corchetes es la que se muestra<br /> y la segunda la que correcta [mostrar/correcta]</h6>
                </div>
              }
              {
                mostrar == "Cloze" &&
                <div>
                  Cloze<br />
                  <textarea rows="5" placeholder='content' onChange={event => setContenido(event.target.value)} /><br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <select onChange={(event) => setTiposTitulosSelected(event.target.value)}>
                    <option></option>
                    {
                      tiposTitulos.cloze.map((value) =>
                        <option>{value}</option>
                      )
                    }
                  </select><br />
                  <button onClick={(e) => agregar('Cloze')} >Cloze</button><br /><br />
                  <h6>Coloque una sola palabra entre corchetes rectos [], coloque * al principio de una palabra clave para destacarla NO use salto de linea, lo hace solo.</h6>
                </div>
              }
              {
                mostrar == "Select" &&
                <div>
                  Multiple Option<br />
                  <textarea rows="5" placeholder='content' onChange={event => setContenido(event.target.value)} /><br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <select onChange={(event) => setTiposTitulosSelected(event.target.value)}>
                    <option></option>
                    {
                      tiposTitulos.select.map((value) =>
                        <option>{value}</option>
                      )
                    }
                  </select><br />
                  <button onClick={(e) => agregar('Select')} >Cargar</button><br /><br />
                  <h6>La primer palabra entre corchetes es la verdadera<br /> y el resto icorrectas [true/false/false/false]</h6>
                </div>
              }
              {
                mostrar == "Save" &&
                <div >
                  <br /><br />Initial data<br />
                  <h5>Name of the exercise</h5>
                  <input type="text" value={guardar.title} name="title" onChange={event => guardarOnChange(event)} />
                  <h5>Course to which it belongs</h5>
                  <select name="courseId" onChange={event => guardarOnChange(event)} value={guardar.courseId}>
                    <option>
                    </option>
                    {cursos &&
                      cursos.map((curso) =>
                        <option value={curso.courseId}>
                          {curso.name}
                        </option>
                      )
                    }
                  </select><br />
                  <h5>Description of exercise</h5>
                  <textarea rows="5" value={guardar.description} name="description" onChange={event => guardarOnChange(event)} />
                  <br />
                  <button onClick={(e) => GuardarGuardar('Continue')} >Save</button>
                </div>
              }
              {
                mostrar == "Option" &&
                <div>
                  <h5>Multiple Option</h5><br />
                  Question<br />
                  <textarea rows="2" name="preg" placeholder='content' onChange={event => opcionOnChange(event)} /><br />
                  <br />
                  <List>
                    {
                      opcionPreguntasCantidad.map((contenido, index) =>
                        <ListItem button key={contenido}>
                          <textarea name="answer" id={index} rows="2" placeholder='Answer' onChange={event => opcionOnChange(event)}></textarea>
                          <select name="value" id={index} onChange={event => opcionOnChange(event)}><option value={false} >False</option><option value={true} defaultValue>true</option></select>
                        </ListItem>
                      )
                    }
                  </List>
                  <Button onClick={event => agregarOpcion(event)}>add answer</Button>
                  <br />
                  <select onChange={(event) => setTiposTitulosSelected(event.target.value)}>
                    <option></option>
                    {
                      tiposTitulos.select.map((value) =>
                        <option>{value}</option>
                      )
                    }
                  </select><br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <Button onClick={event => opcionCrear(event)}>Finalize</Button>
                </div>
              }
              {mostrar == "Arrows" &&
                <div>
                  <h5>exercise of arrows</h5><br />
                  <List>
                    {flechasCantidad.map((text, index) => (
                      <ListItem button key={index}>
                        <textarea rows="2" name="question" id={index} placeholder='Question' onChange={event => flechasOnChange(event)}></textarea>
                        <textarea rows="2" name="answer" id={index} placeholder='Answer' onChange={event => flechasOnChange(event)}></textarea>
                      </ListItem>
                    ))}
                  </List>
                  <Button onClick={event => agregarFlechas(event)}>add answer</Button>
                  <br />
                  <textarea rows="5" placeholder='Clue' onChange={event => setPista(event.target.value)} /><br />
                  <select onChange={(event) => setTiposTitulosSelected(event.target.value)}>
                    <option></option>
                    {
                      tiposTitulos.arrows.map((value) =>
                        <option>{value}</option>
                      )
                    }
                  </select>
                  <Button onClick={event => flechasCrear(event)}>Finalize</Button>
                </div>
              }
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        </div>
      }
      {
        consultaInicial &&
        <div style={{ position: "fixed", top: "0%", left: "0%", width: "100%", height: "100%", backgroundColor: "white", padding: "10%", zIndex: "+1" }}>
          <br /><br />Initial data<br />
          <h5>Name of the exercise</h5>
          <input type="text" placeholder='title' name="title" onChange={event => guardarOnChange(event)} />
          <h5>Course to which it belongs</h5>
          <select name="courseId" onChange={event => guardarOnChange(event)} >
            <option>
            </option>
            {cursos &&
              cursos.map((curso) =>
                <option value={curso.courseId}>
                  {curso.name}
                </option>
              )
            }
          </select>
          <h5>Description of exercise</h5>
          <textarea rows="5" placeholder='Exercise' name="description" onChange={event => guardarOnChange(event)} />
          <br />
          <button onClick={(e) => GuardarGuardar('Continue')} >Save</button>
        </div>
      }
    </>
  );
}


