import * as React from 'react';
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Delete, Edit, Visibility, VisibilityOff, Comment } from '@material-ui/icons';
import { TableBody, TableCell, TableContainer, TableRow, TextField, NativeSelect, FormControl, InputLabel, FilledInput, InputAdornment, IconButton, } from '@material-ui/core';
import { useEffect } from 'react';
import AuthContext from '../store/auth-context'
import axios from 'axios';
import { Alert } from '@mui/material';
import { useHistory } from 'react-router';
import Chat from './chat/Chat';

function SimpleDialog(props) {

  const history = useHistory()
  const authCtx = useContext(AuthContext)
  const token = authCtx.token
  const { onClose, selectedValue, open, dataEdit, deleteId, idChat } = props;
  const [formData, setFormData] = useState(
    {
      studentsId: "",
      studentsName: "",
      studentsEmail: "",
      studentsPhone: "",
      studentsCourse: null,
      studentsPassword: "",
      StudentsRepeatPassword: "",
      studentsRol: "Student"
    }
  )

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleInputChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  const [respondMenssage, setRespondMenssage] = useState()
  const [respondMenssageValue, setRespondMenssageValue] = useState()
  const [course, setCourse] = useState()
  const [chargingCourse, setChargingCourse] = useState("cargando")
  const [viewWindows, setViewWindows] = useState()

  useEffect(() => {
    if (dataEdit) {
      setFormData(
        {
          studentsId: dataEdit.userId,
          studentsName: dataEdit.name,
          studentsEmail: dataEdit.email,
          studentsPhone: dataEdit.phone,
          studentsCourse: dataEdit.courseId,
          studentsPassword: dataEdit.password,
          studentsRepeatPassword: dataEdit.password,
          studentsRol: dataEdit.rol
        }
      )
    }
    axios.get('https://www.api.simplifiedenglishacademy.com.uy/course', {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((res) => {
        let nullCourse = [{
          "courseId": null,
          "name": "    ",
          "description": "null",
          "active": 1
        }]
        let courses = res.data.response
        setCourse(nullCourse.concat(courses))
        setChargingCourse("cargado")

      })
      .catch((error) => {
        setChargingCourse("error")
        authCtx.logout()
        history.replace('/')
      })

    if (deleteId) {
      setViewWindows("delete")
    } else {
      setViewWindows("edit")
    }

    if (idChat) {
      setViewWindows("chat")
    }
  }, []);

  const createUser = (event) => {
    let user = {
      "name": formData.studentsName,
      "email": formData.studentsEmail,
      "password": formData.studentsPassword,
      "phone": formData.studentsPhone,
      "rol": formData.studentsRol,
      "courseId": parseInt(formData.studentsCourse),
      "active": 1
    }

    axios.post('https://www.api.simplifiedenglishacademy.com.uy/users', user, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((res) => {
        setRespondMenssageValue("success")
        setRespondMenssage("user created successfully")
        authCtx.setRefreshEdit(!authCtx.refreshEdit)
        setTimeout(() => {
          handleClose()
          setRespondMenssage("")
        }, 2000);
      })
      .catch((error) => {
        setRespondMenssageValue("error")
        setRespondMenssage(error.response.data.error)
      })
  }

  const editUser = (event) => {
    let user = {
      "name": formData.studentsName,
      "email": formData.studentsEmail,
      "password": formData.studentsPassword,
      "phone": formData.studentsPhone,
      "rol": formData.studentsRol,
      "courseId": parseInt(formData.studentsCourse),
      "active": 1
    }

    axios.put('https://www.api.simplifiedenglishacademy.com.uy/users/' + formData.studentsId, user, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((res) => {
        setRespondMenssageValue("success")
        setRespondMenssage("User edit successfully")
        authCtx.setRefreshEdit(!authCtx.refreshEdit)
        setTimeout(() => {
          handleClose()
          setRespondMenssage("")
        }, 2000);
      })
      .catch((error) => {
        setRespondMenssageValue("error")
        setRespondMenssage(error.response.data.error)
      })
  }

  const sendDelete = (event) => {

    if (event) {
      axios.delete('https://www.api.simplifiedenglishacademy.com.uy/users/' + deleteId, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .then((res) => {
          setRespondMenssageValue("success")
          setRespondMenssage("The user was deleted successfully")
          authCtx.setRefreshEdit(!authCtx.refreshEdit)
          setTimeout(() => {
            handleClose()
            setRespondMenssage("")
          }, 2000);
        })
        .catch((error) => {
          setRespondMenssageValue("error")
          setRespondMenssage("User delete problem")
        })
      authCtx.setRefreshEdit(!authCtx.refreshEdit)
    } else {
      handleClose()
    }
  }

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    repeatPassword: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog onClose={handleClose} open={open} >

      {viewWindows === "delete" &&
        <div style={{ padding: "20px" }}>
          <TableContainer>
            <TableBody>
              <TableRow>
                <TableCell>
                  Really want to delete the student{" "}{formData.studentsName}
                </TableCell>
              </TableRow>
              <TableRow>
                <Button variant='contained'
                  color='primary'
                  onClick={() => sendDelete(true)}
                  style={{ paddingRight: "20px" }}>
                  Yes
                </Button>
                <Button variant='contained'
                  color='primary'
                  onClick={() => sendDelete(false)}>
                  No
                </Button>
              </TableRow>
              <TableRow>
                {respondMenssage}
              </TableRow>
            </TableBody>
          </TableContainer>
        </div>
      }
      {viewWindows === "edit" &&
        <div style={{ padding: "20px" }}>

          {dataEdit ?
            <DialogTitle> Edit student</DialogTitle>
            :
            <DialogTitle> Add student</DialogTitle>
          }
          <form>
            <TableContainer>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="outlined-required"
                      label="Required"
                      defaultValue={formData.studentsName}
                      name="studentsName"
                      onChange={handleInputChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="outlined-required"
                      label="Required"
                      defaultValue={formData.studentsEmail}
                      name="studentsEmail"
                      onChange={handleInputChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Password
                  </TableCell>
                  <TableCell>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                      <InputLabel htmlFor="filled-adornment-password"
                        name="studentsPassword"
                        onChange={handleInputChange}
                        defaultValue={formData.studentsPassword}
                      >Password</InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="studentsPassword"
                        onChange={handleChange('password')}
                        defaultValue={null}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Repeat password
                  </TableCell>
                  <TableCell>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                      <InputLabel htmlFor="filled-adornment-password"
                        name="studentsRepeatPassword"
                        onChange={handleInputChange}
                        defaultValue={formData.StudentsRepeatPassword}
                      >Password</InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.repeatPassword}
                        onChange={handleChange('repeatPassword')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Phone
                  </TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="outlined-required"
                      label="Required"
                      defaultValue={formData.studentsPhone}
                      name="studentsPhone"
                      onChange={handleInputChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Rol
                  </TableCell>
                  <TableCell>
                    <NativeSelect
                      inputProps={{
                        name: 'studentsRol',
                        id: 'uncontrolled-native',
                      }}
                      onChange={handleInputChange}
                      defaultValue="Student"
                    >
                      <option value="Student">Student</option>
                      <option value="Admin">Admin</option>

                    </NativeSelect>
                  </TableCell>
                </TableRow>
                {chargingCourse === "cargado" &&
                  <>
                    <TableRow>
                      <TableCell>
                        Course
                      </TableCell>
                      <TableCell>
                        <NativeSelect
                          inputProps={{
                            name: 'studentsCourse',
                            id: 'uncontrolled-native',
                          }}
                          onChange={handleInputChange}
                          defaultValue={formData.studentsCourse}
                        >{course.map((cours) => {
                          return (
                            <option value={cours.courseId}>{cours.name}</option>
                          )
                        })
                          }
                        </NativeSelect>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {dataEdit ?
                          <Button variant='contained'
                            color='primary'
                            onClick={() => editUser()}>Edit student</Button>
                          :
                          <Button variant='contained'
                            color='primary'
                            onClick={() => createUser()}>Add student</Button>
                        }
                      </TableCell>
                    </TableRow>
                  </>
                }
                {chargingCourse === "cargando" &&
                  <DialogTitle>...Charging Courses</DialogTitle>
                }
                {chargingCourse === "error" &&
                  <Alert severity="error">Error loading courses</Alert>
                }
                {respondMenssage &&
                  <TableRow>
                    <Alert severity={respondMenssageValue}>{respondMenssage}</Alert>
                  </TableRow>
                }
              </TableBody>
            </TableContainer>
          </form>
        </div>
      }

      {viewWindows === "chat" &&

        <>
          <Chat userId={idChat} />
        </>

      }
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function EditStudents({ type, data, deleteId, idChat }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      {
        type === "create" &&
        <Button variant='contained'
          color='primary'
          onClick={handleClickOpen}>Add Student</Button>
      }
      {
        type === "edit" &&
        <Edit onClick={handleClickOpen} />
      }
      {
        type === "delete" &&
        <Delete onClick={handleClickOpen} />
      }
      {
        type === "chat" &&
        <Comment onClick={handleClickOpen} idChat={idChat} />
      }
      <SimpleDialog
        open={open}
        onClose={handleClose}
        dataEdit={data}
        deleteId={deleteId}
        idChat={idChat}
      />
    </>
  );
}

