import { Card, CardContent, Typography, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  customWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "300px",
    height: "200px",
    border: "1px solid",
    borderRadius: "20px",
  },
  Status: {
    width: "250px",
    height: "40px",
    borderRadius: "0 0 20px 20px",
    content: "Completado",
    color: "white",
    lineHeight: "2.33",
  },
}));

export default function ExcerciseCard({ dataCard }) {
  const history = useHistory();
  const customExcerciseStyle = useStyles();

  const viewStudentsExercises = (id) => {
    let path = `/student/exercises/ejercicios/${id}`;

    history.push(path);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        onClick={() => viewStudentsExercises(dataCard.exerciseId)}
      >
        <Card className={customExcerciseStyle.customWrapper}
          sx={
            dataCard.resolved === 0
              ? { borderColor: "#d21919!important" }
              : { borderColor: "#1976d2!important" }
          }
        >
          <Typography
            className={customExcerciseStyle.Status}
            sx={
              dataCard.resolved === 0
                ? { background: "#d21919" }
                : { background: "#1976d2" }
            }
          >
            {dataCard.resolved === 0 ? "Uncomplete" : "Complete"}
          </Typography>
          <CardContent>
            <Typography variant="h5">
              <strong>{dataCard.title}</strong>
            </Typography>
            <Typography>{dataCard.description}</Typography>
          </CardContent>
        </Card>
      </Button>
    </>
  );
}
