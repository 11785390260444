import { EventOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import styles from '../../styles/ejercicios/Select.module.css'

let texto = "";
const separadorInicial = "[";
const separadorFinal = "]";
let iniciaConTexto = true;
let separado = [];
let separadoResuelto = [];
let respuestaCorrecta = [];
let armadoFinal = "";

function fisherYatesShuffle(arr) {
    respuestaCorrecta.push(arr[0])
    for (var i = arr.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1)); //random index
        [arr[i], arr[j]] = [arr[j], arr[i]]; // swap
    }
}
export function Select({ llego, setResultado, resultado, indice, verResolucion, datosResueltos, finalizaCarga, primeraCarga }) {

    function clasificado(llego) {

        let paraSeparar = [];
        armadoFinal = "";
        texto = llego;
        respuestaCorrecta = [];

        if (texto[1] != separadorInicial) {
            iniciaConTexto = true;
        } else {
            iniciaConTexto = false;
        }

        let cortadoInicial = texto.split(separadorInicial);
        let primerEspacio = true;

        for (let index = 0; index < cortadoInicial.length; index++) {
            const element = cortadoInicial[index];
            let cortadoFinal = element.split(separadorFinal)

            if (primerEspacio == true) {
                paraSeparar.push({ "tipo": "texto", "contenido": cortadoFinal[0], "id": "" })
            } else {
                let corte = cortadoFinal[0].split("/");
                fisherYatesShuffle(corte)
                paraSeparar.push({ "tipo": "campo", "contenido": corte, "id": "" })
                if (cortadoFinal[1]) {
                    paraSeparar.push({ "tipo": "texto", "contenido": cortadoFinal[1], "id": "" })
                }
            }
            primerEspacio = false;
        }

        let lugar = 1;

        for (let index = 0; index < paraSeparar.length; index++) {
            const element = paraSeparar[index];
            if (element.tipo == "campo") {
                paraSeparar[index].id = lugar;
                lugar++;
            }
        }
        return paraSeparar
    }

    const [selected, setSelected] = useState([]);
    const [generaCambio, setGeneraCambio] = useState(true)
    const [acertados, setAcertados] = useState([])

    useEffect(() => {
        separadoResuelto = datosResueltos ? clasificado(datosResueltos) : false;
        separado = clasificado(llego);

    }, [])

    const OnChangeSelect = (event) => {
        setSelected(
            oldArray => ({
                ...oldArray,
                [event.target.id]: event.target.value
            }
            )
        )
        setGeneraCambio(!generaCambio)
    }

    useEffect(() => {

        let cargar = "";
        
        separado.map((separo) => {
            if (separo.tipo == "campo") {
                cargar = cargar + "[" + (selected[separo.id] ? selected[separo.id] : "") + "]"
                if (selected[separo.id] == respuestaCorrecta[separo.id - 1]) {
                    setAcertados(
                        oldArray => ({
                            ...oldArray,
                            [separo.id]: true
                        }
                        )
                    )
                } else {
                    setAcertados(
                        oldArray => ({
                            ...oldArray,
                            [separo.id]: false
                        }
                        )
                    )
                }
            }
            separo.tipo == "texto" && (
                cargar = cargar + separo.contenido
            )
            //ahora enviar el dato 
        }
        )
        setResultado(cargar)
    }, [generaCambio])

    useEffect(()=>{
        if (primeraCarga == indice) {

            setGeneraCambio(!generaCambio)        
        }
    },[primeraCarga])

    useEffect(() => {
        let event = {
            target: {
                id: undefined,
                value: undefined
            }
        }

            ;

        setTimeout(() => {
            let count = 1
            for (let index = 0; index < separado.length; index++) {
                if (separado[index].tipo == "campo") {
                    event.target.id = count//index
                    event.target.value = separadoResuelto[index].contenido
                    OnChangeSelect(event)
                    count = count + 1
                }
            }
        }, [100]);
    }, [])

    return (
        <div id="contenedor" className={styles.contenedor}>
            <table>
                <tr>
                    <td>
                        {armadoFinal}
                        <div id="texto" className={styles.texto}>
                            {separado.map((separo, index) => (
                                <>
                                    {separo.tipo == "campo" &&
                                        <>

                                            <select className={styles.palabras} style={acertados[separo.id] === true ? ({ color: "black" }) : (verResolucion ? ({ color: "red" }) : ({ color: "black" }))} id={separo.id} onChange={(event) => OnChangeSelect(event)} onLoad={(event) => OnChangeSelect(event)} >

                                                {!datosResueltos &&
                                                    <option className={styles.palabras}>

                                                    </option>
                                                }
                                                {(datosResueltos && (separadoResuelto[index].contenido == separo.contenido[0])) ?
                                                    <option className={styles.palabras} selected>
                                                        {separo.contenido[0]}
                                                    </option>
                                                    :
                                                    <option className={styles.palabras}>
                                                        {separo.contenido[0]}
                                                    </option>

                                                }
                                                {(datosResueltos && (separadoResuelto[index].contenido == separo.contenido[1])) ?
                                                    <option className={styles.palabras} selected>
                                                        {separo.contenido[1]}
                                                    </option>
                                                    :
                                                    <option className={styles.palabras}>
                                                        {separo.contenido[1]}
                                                    </option>
                                                }
                                                {(datosResueltos && (separadoResuelto[index].contenido == separo.contenido[2])) ?
                                                    <option className={styles.palabras} selected>
                                                        {separo.contenido[2]}
                                                    </option>
                                                    :
                                                    <option className={styles.palabras}>
                                                        {separo.contenido[2]}
                                                    </option>
                                                }
                                                {(datosResueltos && (separadoResuelto[index].contenido == separo.contenido[3])) ?
                                                    <option className={styles.palabras} selected>
                                                        {separo.contenido[3]}
                                                    </option>
                                                    :
                                                    <option className={styles.palabras}>
                                                        {separo.contenido[3]}
                                                    </option>
                                                }



                                            </select>
                                        </>
                                    }
                                    {separo.tipo == "texto" &&
                                        <>
                                            {separo.contenido}
                                        </>
                                    }
                                </>
                            ))}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}
