import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../store/auth-context';
import axios from 'axios';
import FileCard from '../components/estudiante/FileCard';
import { Card, CardContent, CardMedia } from '@mui/material';
import { Pause, PlayArrow } from '@mui/icons-material';



const StudentTopicToListenView = (type) => {
    const [data, setData] = useState([])
    const [playing, setPlaying] = useState([])
    const authCtx = useContext(AuthContext)
    const token = authCtx.token

    const refresh = authCtx.refreshEdit

    useEffect(() => {
        axios.get('https://www.api.simplifiedenglishacademy.com.uy/topic?type=Listen', {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then((res) => {
                setData(res.data.response)
                playngList(res.data.response.length)
            })
            .catch((error) => {
                console.error(error)
            })

    }, [refresh]);

    function playngList (length) {
        const list = new Array(length).fill(true)
        setPlaying(list)
    }

    function playAudio (audio, key) {
        const audioPlay = document.getElementById(audio)
        playing[key] ? audioPlay.play() : audioPlay.pause()
        let newLIst = [...playing]
        newLIst[key] = !newLIst[key]
        setPlaying(newLIst)
    }

    
    return (
        <div>
            {data.map((el, key) => (
                <Card sx={{maxWidth: '200px', cursor: 'pointer'}} onClick={() => playAudio(`item-${key}`, key)}>
                    <audio onEnded={() => playAudio(`item-${key}`, key)} controls key={key} style={{display: "none"}} id={`item-${key}`}>
                        <source src={`https://docs.google.com/uc?export=download&id=${el.fileId}`} type="audio/ogg" />
                            Your browser does not support the audio element.
                    </audio>
                    <CardMedia sx={{width: '100%', display: 'flex'}}>
                        {playing[key] ? <PlayArrow sx={{fontSize: '100px', margin: 'auto'}}/> : <Pause sx={{fontSize: '100px', margin: 'auto'}} /> }
                    </CardMedia>
                    <CardContent>
                    {el.title} - {el.description}
                    </CardContent>
                </Card>
            ))}
        </div>
    )
}


export default StudentTopicToListenView
