import { Alert } from "./Alert"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import AuthContext from "../../store/auth-context"
import ExerciseShow from "./ExerciseShow"

export default function Exercise() {

  const [datos, setDatos] = useState()
  const [datosResueltos, setDatosResueltos] = useState(false)
  const [guardar, setGuardar] = useState()
  const [activeStep, setActiveStep] = useState(0);
  const [verResolucion, setVerResolucion] = useState(false);
  //datos de la resolucion de el ejercicio
  const [showElement, setShowElement] = useState(false)
  //Sirve para hacer andar el Alert
  const [finalizaCarga, setFinalizaCarga] = useState(false);

  const authCtx = useContext(AuthContext)
  const token = authCtx.token
  const { theId } = useParams();

  useEffect(() => {

    axios.get(`https://www.api.simplifiedenglishacademy.com.uy/solution/${theId}`, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((resUno) => {
        if (resUno.data.response.components[0]) {
          let data = resUno.data.response.components
          //Ordena por id
          data.sort(function (a, b) {
            return a.id - b.id;
          });
          
          let lista = []

          for (let index = 0; index < data[data.length - 1].id; index++) {
            lista.push({ "id": index, "type": "nada" })
          }
          for (let index = 0; index < data.length; index++) {
            lista[data[index].id] = data[index]
          }
          setDatosResueltos(lista)
        }
        setVerResolucion(true)
        setTimeout(() => {
          setFinalizaCarga(true)
        }, 100);
      })
      .catch((error) => {
        setDatosResueltos(false)
        setFinalizaCarga(true)
      })

    axios.get(`https://www.api.simplifiedenglishacademy.com.uy/exercises/${theId}`, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((res) => {
        let data = res.data.response.components
        //Ordena por id
        data.sort(function (a, b) {
          return a.id - b.id;
        });

        setDatos(data)
        setTimeout(() => {
          setGuardar(res.data.response)
        }, 100);
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const postSolution = (dato) => {
    setShowElement("Empieza el Show")
    let enviar = JSON.parse(JSON.stringify(guardar))
    enviar.components = dato
    setTimeout(() => {
      axios.post(`https://www.api.simplifiedenglishacademy.com.uy/solution`, enviar, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .then((res) => {
          setShowElement("Alright on a " + JSON.stringify(Math.round(res.data.response.percentage)) + " %." + (res.data.response.percentage == 100 ? " Congratatulations! Your work is perfect!" : res.data.response.percentage <= 50 ? " Your score is insufficient. Check your mistakes and keep on practicing to get better marks next time!" : res.data.response.percentage <= 75 ? " You're on the right track, check your mistakes to keep improving." : ' Just change "check your mistakes" the rest is great!!!!!'))
          setVerResolucion(true)
        })
        .catch((error) => {
          setShowElement("Problemas al mandar la resolucion, revise su coneccion o intente mas tarde.")
        })
    }, 100);
  }

  return (
    <>
      {(finalizaCarga && guardar) ?
        <ExerciseShow datos={datos} setDatos={setDatos} guardar={guardar} setGuardar={setGuardar} theId={theId} activeStep={activeStep} setActiveStep={setActiveStep} postSolution={postSolution} setVerResolucion={setVerResolucion} verResolucion={verResolucion} setShowElement={setShowElement} datosResueltos={datosResueltos} finalizaCarga={finalizaCarga} />
        :
        <>Loading please wait...</>
      }
      <Alert showElement={showElement} setShowElement={setShowElement} />
    </>
  )
}