import React from 'react';
import styles from '../../styles/ejercicios/Completar.module.css'
import { useState } from 'react';

export function CompleteSolution({ texto }) {
    // Divide el texto en palabras y partes que deben completarse
    const parts = texto.split(/\[([^\]]+)\]/);
    const [completions, setCompletions] = useState(parts.map(() => ''));

    const handleChange = (index, value) => {
        const newCompletions = [...completions];
        newCompletions[index] = value;
        setCompletions(newCompletions);
    };

    return (
        <div className={styles.container}>
            <table>
                <tr>
                    <td>
                        {parts.map((part, index) => (
                            index % 2 === 0 ? (
                                <span className={styles.texto} key={index}>{part}</span>
                            ) : (
                                <input className={styles.space}
                                    key={index}
                                    type="text"
                                    readOnly
                                    value={completions[index]}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    placeholder={part}
                                />
                            )
                        ))}

                    </td>
                </tr>
            </table>
        </div>
    );
}
