import React, { useContext, useEffect, useState } from 'react';
import styles from '../../styles/Chat.module.css'
import AuthContext from "../../store/auth-context"
import axios from 'axios';

export default function Chat({ userId }) {

  const [message, setMessage] = useState("")
  const [chatContent, setChatContent] = useState("")
  const [error, setError] = useState("")
  const [errorShow, setErrorShow] = useState("")

  const authCtx = useContext(AuthContext)

  const [idI, setIdI] = useState(authCtx.id)
  const [idYou, setIdYou] = useState(userId ? userId : "52")
  const [chatActualice, setChatActualice] = useState(true)

  const token = authCtx.token

  useEffect(() => {

    axios.get(`https://www.api.simplifiedenglishacademy.com.uy/messages?receiver=${idYou}`, {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((res) => {
        let data = res.data.response
        //Ordena por id
        data.sort(function (a, b) {
          return a.messageId - b.messageId;
        });
        setChatContent(data)
      })
      .catch((error) => {
        console.error(error)
        alert(error)
      })
  }, [chatActualice])

  const sendMessage = () => {
    axios
      .post(`https://www.api.simplifiedenglishacademy.com.uy/messages`, {
        "receiverId": idYou,
        "message": message
      },
        {
          headers: {
            'Authorization': `${token}`
          }
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setMessage("")
          setChatActualice(!chatActualice)
        }
      })
      .catch((error) => {
        setError(true);
        setTimeout(() => {
          setError(false);
          setErrorShow("Check your network connection")
        }, 5000);
        if (error.response.status === 401) {
          setErrorShow("Wrong username or password")
        }

      });
  }
  return (
    <div className={styles.container}>
      <div className={styles.containerTwo}>
        {chatContent && chatContent.map((chat, index) => (
          <div className={chat.senderId == idI ? styles.filaYo : styles.filaEl}>
            <div className={chat.senderId == idI ? styles.yo : styles.el}>
              {chat.message}
            </div>
          </div>
        )
        )
        }
      </div>
      <div className={styles.filaEnviar}>
        <textarea 
          type="text" 
          className={styles.palabras} 
          onChange={(event) => setMessage(event.target.value)} 
          value ={message}
        />
        <button onClick={() => sendMessage()} className={styles.buttonSend}>Send</button>
      </div>
    </div>
  );
}