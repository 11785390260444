import React, { useEffect, useState } from 'react';
import styles from '../../styles/ejercicios/Cloze.module.css'

let texto = "";
const separadorInicial = "[";
const separadorFinal = "]";
let iniciaConTexto = true;
let separado = false;
let separadoResultado = false
let armadoFinal = "";

export function Destacado({ tipo }) {

    let devolver = ""

    if (tipo === "<H1>") {
        devolver = "<H1>"
    } else {
        devolver = "</H1>"
    }
    return <></>
}
function clasificado(llego) {

    let clasifico = [];
    armadoFinal = "";
    texto = llego;

    if (texto[1] != separadorInicial) {
        iniciaConTexto = true;
    } else {
        iniciaConTexto = false;
    }

    let cortadoInicial = texto.split(separadorInicial);
    let primerEspacio = true;

    for (let index = 0; index < cortadoInicial.length; index++) {
        const element = cortadoInicial[index];
        let cortadoFinal = element.split(separadorFinal)

        if (primerEspacio == true) {
            clasifico.push({ "tipo": "texto", "contenido": cortadoFinal[0], "id": "" })
        } else {
            let corte = cortadoFinal[0].split("/");
            clasifico.push({ "tipo": "campo", "contenido": corte[0], "id": "", "respuesta": corte[1] })
            if (cortadoFinal[1]) {
                clasifico.push({ "tipo": "texto", "contenido": cortadoFinal[1], "id": "" })
            }
        }
        primerEspacio = false;
    }

    let lugar = 1;

    for (let index = 0; index < clasifico.length; index++) {
        const element = clasifico[index];
        if (element.tipo == "campo") {
            clasifico[index].id = lugar;
            lugar++;
        }
    }
    return clasifico
}

function separoPalabras(palabras) {

    let dividido = palabras.split(" ")
    let rearmado = []

    for (let index = 0; index < dividido.length; index++) {
        rearmado.push(dividido[index]);
    }
    return rearmado
}

export function Cloze({ llego, resultado, setResultado, indice, verResolucion, datosResueltos, finalizaCarga, primeraCarga }) {

    const [selected, setSelected] = useState([]);
    const [generaCambio, setGeneraCambio] = useState(true)
    const [acertados, setAcertados] = useState([])

    useEffect(() => {
        separado = clasificado(llego);
        separadoResultado = datosResueltos ? clasificado(datosResueltos) : false

        if (separadoResultado) {
            separadoResultado.map((separo, lugar) => {
                if (separo.tipo == "campo") {

                    setSelected(
                        oldArray => (
                            {
                                ...oldArray,
                                [separo.id]: separo.contenido
                            }
                        )
                    )

                }
            }
            )
            setGeneraCambio(!generaCambio)
        }

    }, [])

    useEffect(()=>{
        if (primeraCarga == indice) {
            setGeneraCambio(!generaCambio)      
        }
    },[primeraCarga])

    const OnChangeSimilary = (event) => {
        setSelected(
            oldArray => (
                {
                    ...oldArray,
                    [event.target.id]: event.target.value
                }
            )
        )
        setGeneraCambio(!generaCambio)
    }

    useEffect(() => {

        let cargar = "";

        separado.map((separo) => {

            if (separo.tipo == "campo") {

                cargar = cargar + "[" + (selected[separo.id] ? selected[separo.id] : "") + "]"

                if ((selected[separo.id] && selected[separo.id].toLowerCase()) === (separo.contenido).toLowerCase()) {
                    setAcertados(
                        oldArray => ({
                            ...oldArray,
                            [separo.id]: true
                        }
                        )
                    )
                } else {
                    setAcertados(
                        oldArray => ({
                            ...oldArray,
                            [separo.id]: false
                        }
                        )
                    )

                }
            }
            separo.tipo == "texto" && (
                cargar = cargar + separo.contenido
            )
            //ahora enviar el dato 
        }
        )
        setTimeout(() => {
            setResultado( cargar )            
            }, 100);
    }, [generaCambio])

    return (
        <div id="contenedor" className={styles.contenedor}>
            {separado &&
                <table>
                    <tr>
                        <td>
                            {armadoFinal}
                            <div id="texto" className={styles.texto}>
                                {separado.map((separo, index) => (
                                    <>
                                        {separo.tipo == "campo" &&
                                            <>
                                                <input type="text" className={
                                                    separado[index - 1].tipo ?
                                                        (
                                                            (separado.length > index + 1) ?
                                                                (
                                                                    (separado[index - 1].tipo == "texto" && separado[index + 1].tipo == "texto")
                                                                        ? (styles.palabras)
                                                                        :
                                                                        (
                                                                            (separado[index - 1].tipo == "campo" && separado[index + 1].tipo == "texto")
                                                                                ? (styles.palabrasFin)
                                                                                :
                                                                                (
                                                                                    (separado[index - 1].tipo == "texto" && separado[index + 1].tipo == "campo")
                                                                                        ? (styles.palabrasIni)
                                                                                        : (styles.palabrasMid)
                                                                                )
                                                                        )

                                                                )
                                                                :
                                                                (
                                                                    (separado[index - 1].tipo == "texto") ?
                                                                        (
                                                                            styles.palabrasIni
                                                                        )
                                                                        :
                                                                        (
                                                                            styles.palabrasFin
                                                                        )
                                                                )
                                                        )
                                                        :
                                                        (
                                                            (separado.length > index + 1) ?
                                                                (
                                                                    separado[index + 1].tipo == "texto" ?
                                                                        (
                                                                            styles.palabrasFin
                                                                        )
                                                                        :
                                                                        (
                                                                            styles.palabras
                                                                        )
                                                                )
                                                                :
                                                                (
                                                                    styles.palabras
                                                                )
                                                        )} onChange={(event) => OnChangeSimilary(event)} id={separo.id} defaultValue={separadoResultado ? (verResolucion ? separadoResultado[index].contenido : "") : ""} style={acertados[separo.id] === true ? ({ color: "black" }) : (verResolucion ? ({ color: "red" }) : ({ color: "black" }))} >

                                                </input>
                                            </>
                                        }
                                        {separo.tipo == "texto" &&
                                            <>
                                                {
                                                    (separoPalabras(separo.contenido)).map((palab) =>
                                                        (!palab.includes('*') && !palab.includes('(salto)')) ?
                                                            (

                                                                <div className={styles.textoDividido}>
                                                                    {palab}

                                                                </div>
                                                            )
                                                            :
                                                            (palab.includes('*') ? (
                                                                <div className={styles.palabraClave}>
                                                                    {palab.split("*")}
                                                                </div>
                                                            )
                                                                :
                                                                (
                                                                    <>
                                                                        <div className={styles.palabraSalto}>
                                                                            {palab.split("(salto)")}
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                )
                                                            )
                                                    )
                                                }
                                            </>
                                        }
                                    </>
                                ))}
                            </div>
                        </td>
                    </tr>
                </table>
            }
            {!separado &&
                <>
                    Await please ...
                </>
            }
        </div>
    )
}


