import React, { useEffect, useState } from 'react';
import styles from '../../styles/ejercicios/MultipleOption.module.css'

export function MultipleOption({ opcion, resultado, setResultado, indice, verResolucion, datosResueltos, finalizaCarga, primeraCarga }) {

    const [respond, setRespond] = useState(datosResueltos ? JSON.parse(JSON.stringify(datosResueltos.answer)) : JSON.parse(JSON.stringify(opcion.answer)));

    const onChangeRespond = (event) => {
        let armar = JSON.parse(JSON.stringify(respond))
        armar[event.target.id].value = event.target.checked ? 1 : 0
        setRespond(armar)

        let mandar = JSON.parse(JSON.stringify(opcion))
        mandar.answer = armar

        setTimeout(() => {
            setResultado( armar )
        }, [100]);     
    }
    useEffect(() => {
        let armar = JSON.parse(JSON.stringify(datosResueltos ? datosResueltos.answer : JSON.parse(JSON.stringify(opcion.answer))))
        if (!datosResueltos) {
            for (let index = 0; index < armar.length; index++) {
                armar[index].value = 0
            }
        }
        setRespond(armar)

        let mandar = datosResueltos ? datosResueltos : JSON.parse(JSON.stringify(opcion))

        mandar.answer = armar

        setTimeout(() => {
            setResultado( mandar )
        }, [100]);

        if (datosResueltos) {
            let event = {
                target: {
                    id: undefined,
                    value: undefined
                }
            }
                ;

            setTimeout(() => {
                for (let index = 0; index < datosResueltos.answer.length; index++) {

                    event.target.id = index
                    event.target.value = datosResueltos[0].answer[index].value
                    onChangeRespond(event)

                }
            }, [2000]);
        }
    }, [])

    useEffect(()=>{
        if(primeraCarga == indice){

        setTimeout(() => {
            setResultado( respond )
        }, [100]);    
        }
 
    },[primeraCarga])

    return (
        <div>
            <div className={styles.question}>
                {opcion.question}
            </div>
            <div className={styles.answer}>
                {
                    (datosResueltos ? datosResueltos.answer : opcion.answer).map((text, index) => (
                        <>
                            <div style={respond[index].value == opcion.answer[index].value ? ({ color: "black" }) : (verResolucion ? ({ color: "red" }) : ({ color: "black" }))
                            }>
                                <input type="checkbox" id={index} onChange={(event) => onChangeRespond(event)} defaultValue={text.value} defaultChecked={datosResueltos && (text.value === 1 && "checked")} />
                                {text.answer}
                            </div>
                            <br />
                        </>
                    ))
                }
            </div>
        </div>
    );
}