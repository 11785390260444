import React, { useContext, useEffect, useState } from 'react'
//import Navbar from '../components/navbar/Navbar'
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Modal, Button, TextField, TablePagination, Paper, Box } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import CreateERW from '../components/CreateERW';
import AuthContext from '../store/auth-context';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import EnglishRWDelete from '../components/EnglishRWDelete';

const useStyles = makeStyles((theme) => ({
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

const EnglishInTheRealWorld = () => {
    const [rows, setRows] = useState([])
    const styles = useStyles();
    const history = useHistory();

    const authCtx = useContext(AuthContext)
    const token = authCtx.token

    const refresh = authCtx.refreshEdit

    useEffect(() => {
        axios.get('https://www.api.simplifiedenglishacademy.com.uy/real-world', {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then((res) => {
                axios.get('https://www.api.simplifiedenglishacademy.com.uy/course', {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
                    .then((courseRes) => {
                        let courses = courseRes.data.response
                        let topics = res.data.response
                        let topicAux = []
                        topics.forEach(topic => {
                            var course = courses.find(c => c.courseId === topic.courseId)
                            course !== undefined ? topic.courseName = course.name : topic.courseName = ""
                            topicAux.push(topic)
                        });
                        setRows(topicAux)

                    })
                    .catch((courseError) => {
                        console.error(courseError)
                    })
            })
            .catch((error) => {
                console.error(error)
            })

    }, [refresh]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const viewTopicFile = (file) => {
        window.open(file, file)
    }

    return (
        <div>

            <Box sx={{ p: 5, bgcolor: 'background.paper' }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <CreateERW type="create" />
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell key='course'
                                        align='left'
                                        style={{ minWidth: 170 }}>Course</TableCell>
                                    <TableCell key='title'
                                        align='left'
                                        style={{ minWidth: 170 }}>Title</TableCell>
                                    <TableCell key='description'
                                        align='left'
                                        style={{ minWidth: 170 }}>Description</TableCell>
                                    <TableCell key='actions'
                                        align='left'
                                        style={{ minWidth: 170 }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.topicId}>
                                                <TableCell align='left'>{row.courseName}</TableCell>
                                                <TableCell align='left'>{row.title}</TableCell>
                                                <TableCell align='left'>{row.description}</TableCell>
                                                <TableCell>
                                                    <Visibility onClick={() => viewTopicFile(row.file)} />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <EnglishRWDelete type="delete" fileId={row.fileId} deleteId={row.id} className={styles.iconos} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    )
}

export default EnglishInTheRealWorld
