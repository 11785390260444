import React, { useEffect, useState } from 'react';
import styles from '../../styles/ejercicios/Alert.module.css'

export function Alert({ showElement, setShowElement }) {

    let showControl = 0;

    useEffect(() => {
        showControl > 0 && setShowElement(true)
        showControl++
    }, [showElement]
    )

    return (
        <>
            {showElement &&
                <div className={styles.container}>
                    <div className={styles.containerBlack} onClick={() => setShowElement(false)}>
                    </div>
                    <div className={styles.element}>
                        <div className={styles.text}>
                            <h4>
                                {showElement}
                            </h4>
                        </div>
                        <button onClick={() => setShowElement(false)} className={styles.buttonSend}>To accept</button>
                    </div>
                </div>
            }
        </>
    );
}
