import * as React from 'react';
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { TableBody, TableCell, TableContainer, TableRow, TextField, NativeSelect } from '@material-ui/core';
import { useEffect } from 'react';
import AuthContext from '../store/auth-context'
import axios from 'axios';
import { Alert } from '@mui/material';
import { useHistory } from 'react-router'
import UploadFile from './UploadFile';

function SimpleDialog(props) {

  const history = useHistory()
  const authCtx = useContext(AuthContext)
  const token = authCtx.token
  const { onClose, selectedValue, open, dataEdit, deleteId } = props;
  const [formData, setFormData] = useState(
    {
      topicType: "Read",
      topicTitle: "",
      topicDescription: "",
      topicFile: "",
      topicCourse: null,
      topicFileId: ""
    }
  )
  const [fileData, setFile] = useState()

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleInputChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  const [respondMenssage, setRespondMenssage] = useState()
  const [respondMenssageValue, setRespondMenssageValue] = useState()
  const [course, setCourse] = useState()
  const [chargingCourse, setChargingCourse] = useState("cargando")
  const [culminarCarga, setCulminarCarga] = useState(false)

  useEffect(() => {
    axios.get('https://www.api.simplifiedenglishacademy.com.uy/course', {
      headers: {
        'Authorization': `${token}`
      }
    })
      .then((res) => {
        let nullCourse = [{
          "courseId": null,
          "name": "    ",
          "description": "null",
          "active": 1
        }]
        let courses = res.data.response
        setCourse(nullCourse.concat(courses))
        setChargingCourse("cargado")

      })
      .catch((error) => {
        setChargingCourse("error")
        authCtx.logout()
        history.replace('/')
      })
  }, []);


  const createTopic = (event) => {

    if(culminarCarga){
      let topic = {
        "type": formData.topicType,
        "courseId": parseInt(formData.topicCourse),
        "title": formData.topicTitle,
        "description": formData.topicDescription,
        "file": formData.topicFile,
        "fileId": formData.topicFileId
      }

      axios.post('https://www.api.simplifiedenglishacademy.com.uy/topic', topic, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .then((res) => {
          setRespondMenssageValue("success")
          setRespondMenssage("topic created successfully")
          authCtx.setRefreshEdit(!authCtx.refreshEdit)
          setTimeout(() => {
            handleClose()
            setRespondMenssage("")
          }, 2000);
        })
        .catch((error) => {
          setRespondMenssageValue("error")
          setRespondMenssage(error.response.data.error)
        })
    }else{
      setRespondMenssage("El contenido debe terminar de cargarse")
    }

  }

  const handleCallback = (childData) => {
    setFile(childData)
    formData.topicFileId = childData.id
    formData.topicFile = childData.url
  }

  return (
    <Dialog onClose={handleClose} open={open} >
      <div style={{ padding: "20px" }}>
        <DialogTitle> Add Topic</DialogTitle>
        <form>
          <TableContainer>
            <TableBody>
              <TableRow>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  <NativeSelect
                    inputProps={{
                      name: 'topicType',
                      id: 'uncontrolled-native',
                    }}
                    onChange={handleInputChange}
                    defaultValue="Read"
                  >
                    <option value="Read">Read</option>
                    <option value="Listen">Listeng</option>
                  </NativeSelect>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Title
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    id="outlined-required"
                    label="Required"
                    defaultValue={formData.topicTitle}
                    name="topicTitle"
                    onChange={handleInputChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    id="outlined-required"
                    label="Required"
                    defaultValue={formData.topicDescription}
                    name="topicDescription"
                    onChange={handleInputChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  File
                </TableCell>
                <TableCell>
                  <UploadFile parentCallback={handleCallback} setCulminarCarga={setCulminarCarga}/>
                </TableCell>
              </TableRow>

              {chargingCourse === "cargado" &&
                <>
                  <TableRow>
                    <TableCell>
                      Course
                    </TableCell>
                    <TableCell>
                      <NativeSelect
                        inputProps={{
                          name: 'topicCourse',
                          id: 'uncontrolled-native',
                        }}
                        onChange={handleInputChange}
                        defaultValue={formData.topicCourse}
                      >{course.map((c) => {
                        return (
                          <option value={c.courseId}>{c.name}</option>
                        )
                      })
                        }
                      </NativeSelect>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Button variant='contained'
                        color='primary'
                        onClick={() => createTopic()}>Add Topic</Button>
                    </TableCell>
                  </TableRow>
                </>
              }
              {chargingCourse === "cargando" &&
                <DialogTitle>...Charging Courses</DialogTitle>
              }
              {chargingCourse === "error" &&
                <Alert severity="error">Error loading courses</Alert>
              }
              {respondMenssage &&
                <TableRow>
                  <Alert severity={respondMenssageValue}>{respondMenssage}</Alert>
                </TableRow>
              }
            </TableBody>
          </TableContainer>
        </form>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function CreateTopic({ type, data, deleteId }) {
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);

  };

  return (
    <>
      {
        type === "create" &&
        <Button variant='contained'
          color='primary'
          onClick={handleClickOpen}>Add Topic</Button>
      }
      <SimpleDialog
        open={open}
        onClose={handleClose}
        dataEdit={data}
        deleteId={deleteId}
      />
    </>
  );
}

