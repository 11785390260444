import {Grid, Box, Typography, TextField, Button, Alert} from '@mui/material/'
import { useState, useContext } from 'react'
import { useHistory } from 'react-router'
import AuthContext from '../../store/auth-context'
import axios from 'axios'
import {  Link } from 'react-router-dom'
import '../../App.css'

export default function FormLogin () {
    const [body, setBody] = useState({ email: '', password: '' })
    const [error, setError] = useState(false)
    const history = useHistory()
    const authCtx = useContext(AuthContext)

    const handleChange = e => {
        setBody({
        ...body,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        if(body.email.length === 0){
        }
        else{
            axios.post(`https://www.api.simplifiedenglishacademy.com.uy/users/login`, 
            { 
                email: body.email,
                password: body.password 
            }).then(res => {
                if(res.status === 200){
                    authCtx.login(res.data)
                    history.replace('/courses')
                }
            }).catch((error) => {
                setError(true)
                setTimeout(() => {
                    setError(false)
                }, 5000);
              })
            
        }
        
    }


    return (
        <>
            <Box sx={{
            width: '400px', 
            height: 'max-contain',
            minHeight: '400px' ,
            backgroundColor: 'rgba(255,255,255,0.7)',
            border: '8px solid #52A0EA',
            borderRadius: '36px',
            position: 'relative',
            overflowY: 'hidden',
            zIndex: 1
            }}>
                <Typography  sx={{
                    fontSize:'30px',
                    color: 'white',
                    lineHeight: '60px' ,
                    textAlign: 'center',
                    marginX: 'auto',
                    width:'300px',
                    height: '70px',
                    background: '#52A0EA',
                    borderRadius: '0px 0px 36px 36px'
                }}>Registro de usuario</Typography>
                <Box sx={{padding: 5, textAlign: 'center'}}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                name='nombre'
                                value={body.nombre} 
                                variant='outlined' 
                                fullWidth 
                                color='info' 
                                label="Nombre"
                                onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                name='apellido'
                                value={body.apellido} 
                                variant='outlined' 
                                fullWidth 
                                color='info' 
                                label="Apellido"
                                onChange={handleChange} />
                            </Grid>
                        </Grid>
                    </Box>
                    <TextField
                        name='email'
                        value={body.email} 
                        variant='outlined' 
                        fullWidth 
                        color='info' 
                        label="Email"
                        sx={{marginTop: '1rem'}}
                        onChange={handleChange} />
                    <TextField
                        name='password'
                        value={body.password} 
                        variant='outlined' 
                        fullWidth 
                        color='info' 
                        label="Contraseña" 
                        sx={{marginTop: '1rem'}}
                        onChange={handleChange} />
                    <TextField
                        name='repeatpassword'
                        value={body.rpassword} 
                        variant='outlined' 
                        fullWidth 
                        color='info' 
                        label="Repetir contraseña" 
                        sx={{marginTop: '1rem'}}
                        onChange={handleChange} />
                    <Box 
                        sx={{
                            minHeight: '60px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center'
                        }}>
                        <Typography>
                            <Link to={'/'} style={{
                                color: '#000', 
                                textDecoration: 'none'
                            }} href="#">
                                ¿Ya tienes cuenta?
                            </Link>
                        </Typography>
                    </Box>
                    {error ?
                            <Alert severity="error"><strong>Error! </strong>Las contraseñas no coinciden</Alert>
                    : null
                    }
                    <Button 
                    onClick={onSubmit}
                    size={'large'} 
                    variant='contained' 
                    color='info' 
                    sx={{
                        marginTop: '1rem', 
                        minWidth: '200px'
                    }}>
                        Registrarme
                    </Button>
                </Box>
            </Box>
        </>
    )
} 