import React, { useContext, useEffect, useState } from 'react'
//import Navbar from '../components/navbar/Navbar'
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Modal, Button, TextField, TablePagination, Paper, Box } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import EditStudents from '../components/EditStudents';
import AuthContext from '../store/auth-context';
import axios from 'axios';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

const Students = () => {
    const [rows, setRows] = useState([])
    const styles = useStyles();
    const history = useHistory();

    const authCtx = useContext(AuthContext)
    const token = authCtx.token

    const refresh = authCtx.refreshEdit

    useEffect(() => {
        axios.get('https://www.api.simplifiedenglishacademy.com.uy/users', {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then((res) => {

                axios.get('https://www.api.simplifiedenglishacademy.com.uy/course', {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
                    .then((courseRes) => {
                        let courses = courseRes.data.response
                        let students = res.data.response
                        let studentsAux = []
                        students.forEach(student => {
                            if (student.courseId) {
                                var course = courses.find(c => c.courseId === student.courseId)
                                course !== undefined ? student.courseName = course.name : student.courseName = ""
                            }
                            studentsAux.push(student)
                        });
                        setRows(studentsAux)
                    })
                    .catch((courseError) => {
                        console.error(courseError)
                    })
            })
            .catch((error) => {
                console.error(error)
            })

    }, [refresh]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const viewStudentsExercises = (id) => {
        let path = `/students-exercises/${id}`;

        history.push(path);
    }

    return (
        <div>

            <Box sx={{ p: 5, bgcolor: 'background.paper' }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <EditStudents type="create" />
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell key='rol'
                                        align='left'
                                        style={{ minWidth: 170 }} >Rol</TableCell>
                                    <TableCell key='name'
                                        align='left'
                                        style={{ minWidth: 170 }} >Name</TableCell>
                                    <TableCell key='email'
                                        align='left'
                                        style={{ minWidth: 170 }}>Email</TableCell>
                                    <TableCell key='phone'
                                        align='left'
                                        style={{ minWidth: 170 }}>Phone</TableCell>
                                    <TableCell key='course'
                                        align='left'
                                        style={{ minWidth: 170 }}>Course</TableCell>
                                    <TableCell key='actions'
                                        align='left'
                                        style={{ minWidth: 170 }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.userId}>
                                                <TableCell align='left'>{row.rol}</TableCell>
                                                <TableCell align='left'>{row.name}</TableCell>
                                                <TableCell align='left'>{row.email}</TableCell>
                                                <TableCell align='left'>{row.phone}</TableCell>
                                                <TableCell align='left'>{row.courseName}</TableCell>
                                                <TableCell>
                                                    {!(row.rol === "Admin") && <Visibility onClick={() => viewStudentsExercises(row.userId)} />}
                                                    &nbsp;&nbsp;&nbsp;
                                                    <EditStudents type="chat" idChat={row.userId} className={styles.iconos} />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <EditStudents type="edit" data={row} className={styles.iconos} />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <EditStudents type="delete" deleteId={row.userId} className={styles.iconos} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    )
}

export default Students
