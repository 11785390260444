import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Text } from './Text.jsx';
import { Title } from './Title.jsx';
import { Complete } from './Complete.jsx';
import { Arrows } from './Arrows.jsx';
import { Image } from './Image.jsx';
import { MultipleOption } from './MultipleOption.jsx';
import { Similary } from './Similary.jsx';
import { Cloze } from './Cloze.jsx';
import { useState, useEffect } from 'react';
import styles from '../../styles/ejercicios/Ejercicios.module.css'
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Select } from './Select.jsx';
import {CompleteSolution} from './CompleteSolution.jsx'
import { SelectSolution } from './SelectSolution';
import { SendResult } from './SendResult.jsx';
import SimilarySolution from './SimilarySolution.jsx';
import ClozeSolution from './ClozeSolution.jsx';
import { MultipleOptionSolution } from './MultipleOptionSolution.jsx';

function BalloonTitle(content) {
  return (
    <div className={styles.balloonTitle}>
      {content.children}
    </div>
  )
}

export default function ExerciseShow({ datos, guardar, activeStep, setActiveStep, postSolution, verResolucion, setVerResolucion, setShowElement, finalizaCarga, datosResueltos }) {

  const [nuevosDatos, setNuevosDatos] = useState();
  const [resultado, setResultado] = useState();
  const [primeraCarga, setPrimeraCarga] = useState(false);

  const theme = useTheme();

  const maxSteps = datos.length + 2;

  const handleNext = (e) => {
    e.preventDefault()
    setPrimeraCarga(activeStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(()=>{

    if (activeStep > 0) {
         setNuevosDatos({...nuevosDatos, [activeStep - 1]: resultado}) 
    }
  }
  ,[resultado])

  useEffect(()=>{
    setPrimeraCarga(activeStep);
  },[activeStep])

  const handleBack = (e) => {
    e.preventDefault()
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  //PROCESOS GENERALES

  const enviarResultado = () => {
    let datosGet = JSON.parse(JSON.stringify(datos))

    for (let index = 0; index < datosGet.length; index++) {
      if (datosGet[index].type === "Complete") {
        datosGet[index].content = nuevosDatos[index]
      } else if (datosGet[index].type === "Arrows") {
        datosGet[index].content = nuevosDatos[index]
      } else if (datosGet[index].type === "Option") {
        datosGet[index].answer = nuevosDatos[index]
      } else if (datosGet[index].type === "Similary") {
        datosGet[index].content = nuevosDatos[index]
      } else if (datosGet[index].type === "Cloze") {
        datosGet[index].content = nuevosDatos[index]
      } else if (datosGet[index].type === "Select") {
        datosGet[index].content = nuevosDatos[index]
      } 

    }
    postSolution(datosGet)
    setShowElement("Submitting your answers for correction.")
    setActiveStep(1);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, backgroundColor: 'white', alignItems: "center" }}>
        <MobileStepper
          variant="progress"/*que muestra en el steper*/
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={(e) => handleNext(e)}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={(e) => handleBack(e)} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
        <Box sx={{ p: 2 }} >
          <div id={0} style={{ display: activeStep === 0 ? "inline" : "none" }}>
            <BalloonTitle>{`Course ${guardar.courseId} exercise ${guardar.title}`}</BalloonTitle>
            <Title title={guardar.description} indice={0} />
          </div>
          {
            (finalizaCarga && guardar) && (
              datos.map((data, index) => (
                <>
                  {
                    data.type == "Complete" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>{data.title != "undefined" ? data.title : "Complete the text"}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Complete llego={data.content} 
                                  datosResueltos={datosResueltos ? datosResueltos[index].content : false} 
                                  indice={index + 1} 
                                  resultado={resultado} 
                                  setResultado={setResultado} 
                                  verResolucion={verResolucion} 
                                  finalizaCarga={finalizaCarga} 
                                  primeraCarga={primeraCarga}/>
                        {verResolucion && 
                          <>
                            <BalloonTitle>Solution</BalloonTitle>
                            <CompleteSolution texto={data.content} />
                          </>
                        }
                      </div>
                    )
                  }
                  {
                    data.type == "Similary" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>{data.title != "undefined" ? data.title : "Put the similary word"}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Similary llego={data.content} 
                                  datosResueltos={datosResueltos ? datosResueltos[index].content : false} 
                                  indice={index + 1} 
                                  resultado={resultado} 
                                  setResultado={setResultado} 
                                  verResolucion={verResolucion} 
                                  primeraCarga={primeraCarga}/>
                        {verResolucion && 
                          <>
                            <BalloonTitle>Solution</BalloonTitle>
                            <SimilarySolution resultado={data.content}/>
                          </>
                        }
                      </div>
                    )
                  }
                  {
                    data.type == "Cloze" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>{data.title != "undefined" ? data.title : "Write a second sentence similar to the first. Use the word given. Do not change it"}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Cloze llego={data.content} 
                               datosResueltos={datosResueltos ? datosResueltos[index].content : false} 
                               indice={index + 1} 
                               resultado={resultado} 
                               setResultado={setResultado} 
                               verResolucion={verResolucion} 
                               finalizaCarga={finalizaCarga} 
                               primeraCarga={primeraCarga}/>
                               {verResolucion && 
                          <>
                            <BalloonTitle>Solution</BalloonTitle>
                            <ClozeSolution resultado={data.content}/>
                          </>
                        }
                      </div>
                    )
                  }
                  {
                    data.type == "Select" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>{data.title != "undefined" ? data.title : "Select the correct word"}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Select llego={data.content} 
                                datosResueltos={datosResueltos ? datosResueltos[index].content : false} 
                                indice={index + 1} 
                                resultado={resultado} 
                                setResultado={setResultado} 
                                verResolucion={verResolucion} 
                                primeraCarga={primeraCarga}/>
                        {  verResolucion && 
                          <>
                            <BalloonTitle>Solution</BalloonTitle>
                            <SelectSolution text={data.content} />
                          </>
                        }
                      </div>
                    )
                  }
                  {
                    data.type == "Arrows" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <BalloonTitle>{data.title != "undefined" ? data.title : "Put the answer in the correct position"}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Arrows llego={data.content} 
                                datosResueltos={datosResueltos ? datosResueltos[index].content : false} 
                                indice={index + 1} 
                                resultado={resultado} 
                                setResultado={setResultado}  
                                verResolucion={verResolucion} 
                                finalizaCarga={finalizaCarga} 
                                primeraCarga={primeraCarga}/>
                        {verResolucion && 
                        <>
                          <BalloonTitle>"Solution"</BalloonTitle>
                          <Arrows llego={data.content} 
                                  datosResueltos={data.content} 
                                  indice={index + 1} 
                                  resultado={data.content} 
                                  setResultado={setResultado}  
                                  verResolucion={verResolucion} 
                                  finalizaCarga={finalizaCarga} 
                                  primeraCarga={primeraCarga}/>
                        </>
                        }
                      </div>
                    )
                  }
                  {
                    data.type == "Text" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>{data.title != "undefined" ? data.title : "Read the text for complete de next exercise"}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Text texto={data.content} indice={index + 1} />
                      </div>
                    )
                  }
                  {
                    data.type == "Title" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>{`Course ${datos[0].exerciseId} exercise ${datos[0].title}`}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Title title={data.content} indice={index + 1} />
                      </div>
                    )
                  }
                  {
                    data.type == "Image" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>See the image for complete the next exercise</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <Image imagen={data.content} indice={index + 1} />
                      </div>
                    )
                  }
                  {
                    data.type == "Option" && (
                      <div id={index + 1} style={{ display: activeStep === (index + 1) ? "inline" : "none" }}>
                        <div style={{ display: "flex" }}>
                          <BalloonTitle>{data.title != "undefined" ? data.title : "Select the correct answer"}</BalloonTitle>
                          {data.clue &&
                            <button className={styles.clueButton} onClick={() => setShowElement(data.clue)}>!</button>
                          }
                        </div>
                        <MultipleOption opcion={data} 
                                        datosResueltos={datosResueltos ? datosResueltos[index] : false} 
                                        indice={index + 1} resultado={resultado} 
                                        setResultado={setResultado} 
                                        verResolucion={verResolucion} 
                                        finalizaCarga={finalizaCarga} 
                                        primeraCarga={primeraCarga}/>
                        {verResolucion && 
                        <>
                          <BalloonTitle>"Solution"</BalloonTitle>
                          {<MultipleOptionSolution opcion={data} />}
                        </>
                        }
                      </div>
                    )
                  }

                </>
              )
              )
            )
          }
          <div id={datos.length + 2} style={{ display: activeStep === (datos.length + 1) ? "inline" : "none" }}>
            <BalloonTitle>Finalize Exercise</BalloonTitle>
            <SendResult enviarResultado={enviarResultado} verResolucion={verResolucion} />
          </div>
        </Box>
      </Box>
    </>
  );
}




















