import React, { useContext, useState } from 'react'
import {Drawer, IconButton } from '@mui/material/'
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import AuthContext from '../../../store/auth-context';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { useHistory } from 'react-router'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: 'auto',
    },
    title: {
        flexGrow: 1,
    },
    paper: {
        background: '#3f51b5'
    },
}));

function DrawerComponent() {

    const history = useHistory()
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(true)
    const authCtx = useContext(AuthContext)
    const logOuthandler = (e) => {
        authCtx.logout()
        history.replace('/')
    }

    return (
        <div className={classes.menuButton}>
            <Drawer onClose={() => setOpenDrawer(false)} open={openDrawer} classes={{ paper: classes.paper }}>
                <Tabs orientation="vertical" indicatorColor='secondary'>
                    {authCtx.isAdmin && <Tab label='Courses' component={Link} to="/courses" />}
                    {authCtx.isAdmin && <Tab label='Students' component={Link} to="/students" />}
                    {authCtx.isAdmin && <Tab label='Excercies' component={Link} to="/exercises" />}
                    {authCtx.isAdmin && <Tab label='Topics' component={Link} to="/topics" />}
                    {authCtx.isAdmin && <Tab label='Englis in the real world' component={Link} to="/english-real-world" />}
                    {authCtx.isAdmin && <Tab label='Log out' onClick={logOuthandler} />}
                </Tabs>
            </Drawer>
            <IconButton color="inherit" aria-label="menu" onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
            </IconButton>
        </div>
    )
}

export default DrawerComponent
