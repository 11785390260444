import React from 'react';
import styles from '../../styles/ejercicios/Title.module.css'

export function Title({ title }) {
    return (
        <div className={styles.container}>
            <div>
                <img src='eric.png' className={styles.boy} />
            </div>
            <div className={styles.balloon}>
                <h4>
                    {title}
                </h4>
            </div>
        </div>
    );
}