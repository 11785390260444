//import Navbar from '../components/navbar/Navbar'
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Modal, Button, TextField, TablePagination, Paper, Box } from '@material-ui/core';
import { Edit, Delete, Visibility } from '@material-ui/icons';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../store/auth-context';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import ExerciseDelete from '../components/ExerciseDelete';


const Exercises = () => {

    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        iconos: {
            cursor: 'pointer',
        },
        inputMaterial: {
            width: '100%'
        }
    }));
    const [rows, setRows] = useState([])
    const styles = useStyles();
    const authCtx = useContext(AuthContext)
    const token = authCtx.token

    useEffect(() => {
        axios.get('https://www.api.simplifiedenglishacademy.com.uy/exercises', {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then((res) => {
                axios.get('https://www.api.simplifiedenglishacademy.com.uy/course', {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
                    .then((courseRes) => {
                        let courses = courseRes.data.response
                        let exercises = res.data.response
                        let exerciseAux = []
                        exercises.forEach(exercise => {
                            var course = courses.find(c => c.courseId === exercise.courseId)
                            exercise.courseName = course.name
                            exerciseAux.push(exercise)
                        });
                        setRows(exerciseAux)

                    })
                    .catch((courseError) => {
                        console.error(courseError)
                    })

            })
            .catch((error) => {
                console.error(error)
            })

    }, [authCtx.refreshEdit]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const addExercise = (algo) => {
        let path = `/ejercicioseditar`;
        history.push(path);
    }

    const viewExercise = (id) => {
        let path = `/ejercicios/${id}`;

        history.push(path);
    }

    return (
        <div>
            <Box sx={{ p: 5, bgcolor: 'background.paper' }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <Button variant='contained'
                        color='primary'
                        onClick={addExercise}>Add exercise
                    </Button>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell key='title'
                                        align='left'
                                        style={{ minWidth: 170 }} >Title</TableCell>
                                    <TableCell key='description'
                                        align='left'
                                        style={{ minWidth: 170 }}>Description</TableCell>
                                    <TableCell key='course'
                                        align='left'
                                        style={{ minWidth: 170 }}>Course</TableCell>
                                    <TableCell key='actions'
                                        align='left'
                                        style={{ minWidth: 100 }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.exerciseId}>
                                                <TableCell align='left'>{row.title}</TableCell>
                                                <TableCell align='left'>{row.description}</TableCell>
                                                <TableCell align='left'>{row.courseName}</TableCell>
                                                <TableCell>
                                                    <Visibility onClick={() => viewExercise(row.exerciseId)} />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <ExerciseDelete type="delete" deleteId={row.exerciseId} className={styles.iconos} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    )
}

export default Exercises
