import React from 'react';
import styles from '../../styles/ejercicios/Completar.module.css'
import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex, droppableId) => {
    const result = [...list];
    if (droppableId != "tasks") {
        for (let indexB = 0; indexB < result.length; indexB++) {
            if (result[indexB].posicion == droppableId) {
                result[indexB].posicion = "tasks";
            }
        }
    }

    result[endIndex].nuevaPosicion = result[startIndex].nuevaPosicion;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    if (endIndex < result.length) {
        if (droppableId != "tasks") {
            result[endIndex].posicion = droppableId;
        } else {
            result[endIndex].posicion = droppableId;
        }
    }
    return result;
}

function fisherYatesShuffle(arr) {
    for (var i = arr.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1)); //random index
        [arr[i], arr[j]] = [arr[j], arr[i]]; // swap
    }
}

function separoPalabras(palabras) {
    let dividido = palabras.split(" ")
    let rearmado = []
    for (let index = 0; index < dividido.length; index++) {
        rearmado.push(dividido[index]);
    }
    return rearmado
}

export function Complete({ llego, indice, resultado, setResultado, verResolucion, datosResueltos, finalizaCarga, primeraCarga }) {

    const separadorInicial = "[";
    const separadorFinal = "]";
    let iniciaConTexto = true;
    let separado = [];
    let posicion = "tasks"

    const [tasks, setTasks] = useState([]);
    const [modelo, setModelo] = useState([]);

    const armarJson = () => {

        let ayudaFinal = [];

        for (let index = 0; index < modelo.length; index++) {

            let convertir = Object.values(modelo[index]);

            if (convertir[2] == "texto") {
                ayudaFinal.push(convertir[1])
            } else {
                ayudaFinal.push("[]");
            }
        }

        for (let index = 0; index < tasks.length; index++) {

            let convertir = Object.values(tasks[index]);

            if (convertir[3] == "campo") {
                ayudaFinal[Number(convertir[2])] = "[" + convertir[1] + "]";
            }
        }

        ayudaFinal = ayudaFinal.join('');
        setResultado(ayudaFinal )
    }

    useEffect(() => {
        let ayuda = rearmarDatos(llego)

        setModelo([...ayuda])
        if (datosResueltos) {
            posicion = "index";
            let ayuda2 = rearmarDatos(datosResueltos)
            setTasks([...ayuda2])
        } else {
            posicion = "tasks"
            let mezclar = [...ayuda]
            fisherYatesShuffle(mezclar)
            setTasks(mezclar)
        }
    }, [finalizaCarga]);

    useEffect(()=>{

        if (primeraCarga == indice) {
        armarJson();      
        }

    },[primeraCarga])

    function rearmarDatos(texto) {
        let ayuda = [];
        if (texto[1] != separadorInicial) {
            iniciaConTexto = true;
        } else {
            iniciaConTexto = false;
        }
        let cortadoInicial = texto.split(separadorInicial);
        let primerEspacio = true;
        for (let indexA = 0; indexA < cortadoInicial.length; indexA++) {
            const element = cortadoInicial[indexA];
            let cortadoFinal = element.split(separadorFinal)
            if (primerEspacio == true) {
                separado.push({ "tipo": "texto", "contenido": cortadoFinal[0], "id": "" })
            } else {
                separado.push({ "tipo": "campo", "contenido": cortadoFinal[0], "id": "" })
                if (cortadoFinal[1]) {
                    separado.push({ "tipo": "texto", "contenido": cortadoFinal[1], "id": "" })
                }
            }
            primerEspacio = false;
        }

        for (let index = 0; index < separado.length; index++) {
            const element = separado[index];
            if (element.tipo == "campo") {
                separado[index].id = index;
                let ayudaId = index + "";
                ayuda.push({ id: ayudaId, text: element.contenido, posicion: posicion == "tasks" ? "tasks" : index, inicio: "campo", nuevaPosicion: ayudaId })
            } else {
                separado[index].id = index;
                let ayudaId = index + "";
                ayuda.push({ id: ayudaId, text: element.contenido, posicion: "texto", inicio: "texto", nuevaPosicion: ayudaId })
            }
        }
        separado = [];
        return ayuda
    }

    return (
        <div id="contenedor" className={styles.container}>
            {finalizaCarga ? (
                <DragDropContext onDragEnd={(result) => {
                    const { source, destination } = result;
                    if (!destination) {
                        return;
                    }
                    if (
                        source.index === destination.index &&
                        source.droppableId === destination.droppableId
                    ) {
                        return;
                    }
                    setTasks((prevTasks) =>
                        reorder(prevTasks, source.index, destination.index, destination.droppableId)
                    );
                    setTimeout(() => {
                        armarJson();
                    }, 1000);
                }}
                >
                    <table>
                        <tr>
                            <td>
                                <div className={styles.text}>
                                    {modelo.map((task, index) => (
                                        task.inicio === "campo" ? (
                                            <Droppable droppableId={index}>
                                                {(droppableProvided) => (
                                                    <div
                                                        {...droppableProvided.droppableProps}
                                                        ref={droppableProvided.innerRef}
                                                        className={styles.space}>
                                                        {
                                                            tasks.map((task2, index2) => (
                                                                task2.posicion == index &&

                                                                <Draggable key={task2.id} draggableId={task2.id} index={index2}>
                                                                    {(draggableProvided) => (
                                                                        <div
                                                                            {...draggableProvided.draggableProps}
                                                                            ref={draggableProvided.innerRef}
                                                                            {...draggableProvided.dragHandleProps}
                                                                            className="task-item"
                                                                        >
                                                                            <div style={modelo[index].text == task2.text ? ({ color: "black" }) : (verResolucion ? ({ color: "red" }) : ({ color: "black" }))}>
                                                                                {task2.text}
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))
                                                        }
                                                        {droppableProvided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        ) : (task.posicion === "texto",
                                            <>
                                                {separoPalabras(task.text).map
                                                    ((palab) =>
                                                        <div className={styles.textoDividido}>
                                                            {palab}
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    ))
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <Droppable droppableId="tasks">
                                        {(droppableProvided) => (
                                            <div
                                                {...droppableProvided.droppableProps}
                                                ref={droppableProvided.innerRef}
                                                className={styles.movableContainer}>
                                                {
                                                    tasks.map((task, index) => (
                                                        task.posicion === "tasks" &&
                                                        <Draggable key={task.id} draggableId={task.id} index={index}>
                                                            {(draggableProvided) => (
                                                                <div
                                                                    {...draggableProvided.draggableProps}
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.dragHandleProps}
                                                                    className={styles.words}
                                                                >
                                                                    {task.text}</div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                {droppableProvided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </td>
                        </tr>
                    </table>
                </DragDropContext>
            ) : (
                <>
                    Await please...
                </>
            )
            }
        </div>
    )
}


