import React, { useState } from "react"
import ExerciseShow from "./ExerciseShow"
import ExerciseCreate from "./ExerciseCreate"

export default function Exercise() {

    const [datos, setDatos] = useState([])
    const [guardar, setGuardar] = React.useState([]);
    const [activeStep, setActiveStep] = useState(0);

    return (
        <>
            <ExerciseShow datos={datos} guardar={guardar} activeStep={activeStep} setActiveStep={setActiveStep} verResolucion={false} datosResueltos={false} finalizaCarga={true} />
            <ExerciseCreate datos={datos} setDatos={setDatos} guardar={guardar} setGuardar={setGuardar} activeStep={activeStep} setActiveStep={setActiveStep} />
        </>
    )

}