import * as React from "react";
import { useState, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Delete, Edit } from "@material-ui/icons";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import { useEffect } from "react";
import AuthContext from "../store/auth-context";
import axios from "axios";
import { Alert } from "@mui/material";

function SimpleDialog(props) {
  const [respondMenssage, setRespondMenssage] = useState();
  const [respondMenssageValue, setRespondMenssageValue] = useState();
  const { onClose, selectedValue, open, dataEdit, deleteId } = props;
  const [formData, setFormData] = useState({
    courseId: "",
    courseName: "",
    courseDescription: "",
  });

  const [viewWindows, setViewWindows] = useState();

  useEffect(() => {
    if (dataEdit) {
      setFormData({
        courseId: dataEdit.courseId,
        courseName: dataEdit.name,
        courseDescription: dataEdit.description,
      });
    }

    if (deleteId) {
      setViewWindows("delete");
    } else {
      setViewWindows("edit");
    }
  }, []);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const sendDataCreate = (event) => {
    let armando = {
      name: formData.courseName,
      description: formData.courseDescription,
    };
    axios
      .post("https://www.api.simplifiedenglishacademy.com.uy/course", armando, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setRespondMenssageValue("success");
        setRespondMenssage("course created successfully");
        authCtx.setRefreshEdit(-authCtx.refreshEdit);
        setTimeout(() => {
          handleClose();
          setRespondMenssage("");
        }, 2000);
      })
      .catch((error) => {
        setRespondMenssageValue("error");
        setRespondMenssage("Course creation problem");
      });
  };

  const sendDataEdit = (event) => {
    let armando = {
      courseId: formData.courseId,
      name: formData.courseName,
      description: formData.courseDescription,
    };
    axios
      .put(
        "https://www.api.simplifiedenglishacademy.com.uy/course/" +
          formData.courseId,
        armando,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        setRespondMenssageValue("success");
        setRespondMenssage("course created successfully");
        authCtx.setRefreshEdit(-authCtx.refreshEdit);
        setTimeout(() => {
          handleClose();
          setRespondMenssage("");
        }, 2000);
      })
      .catch((error) => {
        setRespondMenssageValue("error");
        setRespondMenssage("Course creation problem");
      });
  };

  const sendDelete = (event) => {
    if (event) {
      axios
        .delete(
          "https://www.api.simplifiedenglishacademy.com.uy/course/" + deleteId,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((res) => {
          setRespondMenssageValue("success");
          setRespondMenssage("The course was deleted successfully");
          authCtx.setRefreshEdit(-authCtx.refreshEdit);
          setTimeout(() => {
            handleClose();
            setRespondMenssage("");
          }, 2000);
        })
        .catch((error) => {
          setRespondMenssageValue("error");
          setRespondMenssage("Course delete problem");
        });
      authCtx.setRefreshEdit(!authCtx.refreshEdit);
    } else {
      handleClose();
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {viewWindows === "delete" && (
        <div style={{ padding: "20px" }}>
          <TableContainer>
            <TableBody>
              <TableRow>
                <TableCell>
                  Really want to delete the course {formData.courseName}
                </TableCell>
              </TableRow>
              <TableRow>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => sendDelete(true)}
                  style={{ paddingRight: "20px" }}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => sendDelete(false)}
                >
                  No
                </Button>
              </TableRow>
              <TableRow>{respondMenssage}</TableRow>
            </TableBody>
          </TableContainer>
        </div>
      )}
      {viewWindows === "edit" && (
        <div style={{ padding: "20px" }}>
          {dataEdit ? (
            <DialogTitle> Edit backup account</DialogTitle>
          ) : (
            <DialogTitle> Set backup account</DialogTitle>
          )}

          <form>
            <TableContainer>
              <TableBody>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="outlined-required"
                      label="Required"
                      defaultValue={formData.courseName}
                      name="courseName"
                      onChange={handleInputChange}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>
                    <TextField
                      required
                      id="outlined-multiline-flexible"
                      label="Required"
                      defaultValue={formData.courseDescription}
                      name="courseDescription"
                      onChange={handleInputChange}
                      multiline
                      rows={4}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {dataEdit ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => sendDataEdit()}
                      >
                        Edit course
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => sendDataCreate()}
                      >
                        Add course
                      </Button>
                    )}
                  </TableCell>
                </TableRow>

                {respondMenssage && (
                  <TableRow>
                    <Alert severity={respondMenssageValue}>
                      {respondMenssage}
                    </Alert>
                  </TableRow>
                )}
              </TableBody>
            </TableContainer>
          </form>
        </div>
      )}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function EditCourses({ type, data, deleteId }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      {type === "create" && (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add course
        </Button>
      )}
      {type === "edit" && <Edit onClick={handleClickOpen} />}

      {type === "delete" && <Delete onClick={handleClickOpen} />}

      <SimpleDialog
        open={open}
        onClose={handleClose}
        dataEdit={data}
        deleteId={deleteId}
      />
    </>
  );
}
