import { Card, CardContent, Typography, Grid, Button } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  customWrapper: {
    width: '200px',
    height: '200px',
    cursor: 'pointer',
    backgroundColor: '',
    borderRadius: '10px',
    borderTop: '1px groove lightgray',
    borderLeft: '4px dashed #585858!important',
    borderRight: '5px groove darkgray',
    borderBottom: '5px groove darkgray',
    background: 'linear-gradient(317deg,#e3e3e3 5%, #979393 7%, white 7%, transparent)'
  }
}));

export default function FileCard({ dataCard }) {

  const history = useHistory();
  const customExcerciseStyle = useStyles();

  const viewStudentFile = (file) => {
    window.open(file, file)
  }



  return (
    <>
      <Button sx={{ textTransform: 'none' }} onClick={() => viewStudentFile(dataCard.file)}>
        <Card className={customExcerciseStyle.customWrapper}>
          <CardContent>
            <Typography variant="h5"><strong>{dataCard.title}</strong></Typography>
            <Typography >{dataCard.description}</Typography>
          </CardContent>
        </Card>
      </Button>
    </>
  );
}
