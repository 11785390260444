import * as React from 'react';
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Delete, Edit } from '@material-ui/icons';
import { TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import AuthContext from '../store/auth-context';
import axios from 'axios';

function SimpleDialog(props) {
  const [respondMenssage, setRespondMenssage] = useState()
  const [respondMenssageValue, setRespondMenssageValue] = useState()
  const { onClose, selectedValue, open, fileId, deleteId } = props;
  const [viewWindows, setViewWindows] = useState()

  useEffect(() => {
    setViewWindows("delete")
  }, []);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const authCtx = useContext(AuthContext)
  const token = authCtx.token

  const sendDelete = (event) => {
    if (event) {
      axios.delete('https://www.api.simplifiedenglishacademy.com.uy/topic/' + deleteId, {
        headers: {
          'Authorization': `${token}`
        }

      }).then((res) => {
        setRespondMenssageValue("success")
        setRespondMenssage("The file deleted successfully")
        authCtx.setRefreshEdit(-authCtx.refreshEdit)
        setTimeout(() => {
          handleClose()
          setRespondMenssage("")
        }, 2000);
      }).catch((error) => {
        setRespondMenssageValue("error")
        setRespondMenssage("File delete problem")
      })
      deleteFile(fileId)
      authCtx.setRefreshEdit(!authCtx.refreshEdit)
    } else {
      handleClose()
    }
  }

  const deleteFile = (fileId) => {
    var dataSend = { dataReq: { id: fileId }, fname: "deleteFilesToGoogleDrive" }; //preapre info to send to API
    fetch('https://script.google.com/macros/s/AKfycbyF3vWtDo4LOQ6S7ADD_WzqNjmC3bhJlUzGUS3kiNTK3q8FjVKs/exec', //your AppsScript URL
      { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
      .then(res => res.json()).then((a) => {
        handleClose()
      })
  }
  return (
    <Dialog onClose={handleClose} open={open} >
      {viewWindows === "delete" &&
        <div style={{ padding: "20px" }}>
          <TableContainer>
            <TableBody>
              <TableRow>
                <TableCell>
                  Really want to delete this file?
                </TableCell>
              </TableRow>
              <TableRow>
                <Button variant='contained'
                  color='primary'
                  onClick={() => sendDelete(true)}
                  style={{ paddingRight: "20px" }}>
                  Yes
                </Button>
                <Button variant='contained'
                  color='primary'
                  onClick={() => sendDelete(false)}>
                  No
                </Button>
              </TableRow>
              <TableRow>
                {respondMenssage}
              </TableRow>
            </TableBody>
          </TableContainer>
        </div>
      }
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function TopicDelete({ type, data, fileId, deleteId }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Delete onClick={handleClickOpen} />
      <SimpleDialog
        open={open}
        onClose={handleClose}
        dataEdit={data}
        fileId={fileId}
        deleteId={deleteId}
      />
    </>
  );
}
