import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import {makeStyles} from '@material-ui/core/styles';
import {Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Modal, Button, TextField, TablePagination, Paper,Box } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import AuthContext from '../store/auth-context';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    iconos:{
      cursor: 'pointer'
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));

const StudentsExercises = () => {
    const [rows, setRows] = useState([])
    const styles= useStyles();
    const { userId } = useParams();

    const authCtx = useContext(AuthContext)
    const token = authCtx.token 

    const refresh =authCtx.refreshEdit
  
    useEffect(() => {
        axios.get(`https://www.api.simplifiedenglishacademy.com.uy/exercises/users/${userId}`, {
            headers: {
            'Authorization': `${token}`
            }
        })
        .then((res) => {
            setRows(res.data.response)
        })
        .catch((error) => {
            console.error(error)
        })

    },[refresh]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeCheckBox = (userExercise, checked) => {
        let assigned = checked ? 1 : 0
        userExercise.assigned = assigned

        axios.put(`https://www.api.simplifiedenglishacademy.com.uy/exercises/users/${userId}`, userExercise,  {
              headers: {
              'Authorization': `${token}`
              }
          })
          .then((res) => {

          })
          .catch((error) => {
              console.error(error)
          })
    };

    return (
        <div>
            <Box sx={{ p: 5, bgcolor: 'background.paper' }}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell  key='title'
                                        align='left'
                                        style={{ minWidth: 170 }} >Title</TableCell>
                            <TableCell key='description'
                                        align='left'
                                        style={{ minWidth: 170 }}>Description</TableCell>
                            <TableCell key='assigned'
                                        align='left'
                                        style={{ minWidth: 170 }}>Assigned</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.exerciseId}>
                                <TableCell align='left'>{row.title}</TableCell>
                                <TableCell align='left'>{row.description}</TableCell>
                                <TableCell align='left'>
                                    <Checkbox defaultChecked={(row.assigned === 1)}
                                        onChange={e => {
                                            handleChangeCheckBox(row ,e.target.checked);
                                        }}
                                    />
                                    
                                </TableCell>
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5,10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            </Box>
        </div>
    )
}

export default StudentsExercises
