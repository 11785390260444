//import Navbar from '../components/navbar/Navbar'
import {makeStyles} from '@material-ui/core/styles';
import {Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Modal, Button, TextField, TablePagination, Paper,Box } from '@material-ui/core';
import {Edit, Delete} from '@material-ui/icons';
import EditCourses from '../components/EditCourses';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../store/auth-context';
import axios from 'axios';

const Courses = () => {

const useStyles = makeStyles((theme) => ({
    iconos:{
      cursor: 'pointer',
    }, 
    inputMaterial:{
      width: '100%'
    }
  }));

     const [rows, setRows] = useState([])
    const styles= useStyles(); 
  
  const authCtx = useContext(AuthContext)
  const token = authCtx.token 

     useEffect(() => {
        axios.get('https://www.api.simplifiedenglishacademy.com.uy/course', {
            headers: {
            'Authorization': `${token}`
            }
        })
        .then((res) => {
            setRows(res.data.response)

        })
        .catch((error) => {
            console.error(error)
        })

    },[authCtx.refreshEdit]); 

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <Box sx={{ p: 5, bgcolor: 'background.paper' }}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <form>
                <EditCourses type = "create"/>     
                </form>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell  key='name'
                                        align='left'
                                        style={{ minWidth: 170 }} >Name</TableCell>
                            <TableCell key='name'
                                        align='left'
                                        style={{ minWidth: 170 }}>Description</TableCell>
                            <TableCell key='name'
                                        align='left'
                                        style={{ minWidth: 100}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.courseId}>
                                <TableCell align='left'>{row.name}</TableCell>
                                <TableCell align='left'>{row.description}</TableCell>
                                <TableCell>
                                    <EditCourses type = "edit" data = {row} className={styles.iconos}/>
                                    &nbsp;&nbsp;&nbsp;
                                    <EditCourses type = "delete" deleteId = {row.courseId} className={styles.iconos}/>
                                </TableCell>
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5,10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            </Box>
        </div>
    )
}

export default Courses
