import * as React from 'react';
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Delete, Edit } from '@material-ui/icons';
import { TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import AuthContext from '../store/auth-context';
import axios from 'axios';

function SimpleDialog(props) {
  const [respondMenssage, setRespondMenssage] = useState()
  const [respondMenssageValue, setRespondMenssageValue] = useState()
  const { onClose, selectedValue, open, dataEdit, deleteId } = props;
  const[viewWindows, setViewWindows] = useState()
  
  useEffect(() => {
    setViewWindows("delete")
  },[]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const authCtx = useContext(AuthContext)
  const token = authCtx.token 

  const sendDelete = (event) =>{
    if(event){
      axios.delete('https://www.api.simplifiedenglishacademy.com.uy/exercises/' + deleteId, {
      headers: {
        'Authorization': `${token}`
      }
    }).then((res) => {
        setRespondMenssageValue("success")
        setRespondMenssage("The exercise was deleted successfully")
        authCtx.setRefreshEdit(-authCtx.refreshEdit)
        setTimeout(() => {
          handleClose()
          setRespondMenssage("")
        }, 2000);
      }).catch((error) => {
        setRespondMenssageValue("error")
        setRespondMenssage("Exercise delete problem")
      })
      authCtx.setRefreshEdit(!authCtx.refreshEdit)
    }else{
      handleClose()
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} >
      {viewWindows === "delete" &&
        <div style={{padding: "20px"}}>
          <TableContainer>
            <TableBody>
              <TableRow>
                <TableCell>
          Really want to delete the exercise?
                </TableCell>
              </TableRow>
              <TableRow>
                <Button variant='contained'
                        color='primary' 
                        onClick={()=> sendDelete(true)}
                        style={{paddingRight: "20px"}}>
                    Yes
                </Button>
                <Button variant='contained'
                        color='primary' 
                        onClick={()=> sendDelete(false)}>
                    No
                </Button>
              </TableRow>
              <TableRow>
              {respondMenssage}
              </TableRow>
            </TableBody>
          </TableContainer>
        </div>
      }
    </Dialog>        
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function ExerciseDelete({ type, data, deleteId }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Delete onClick={handleClickOpen}/>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        dataEdit= {data}
        deleteId={deleteId}
      />
    </>
  );
}
