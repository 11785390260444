import React, { useEffect, useState } from 'react';
import styles from '../../styles/ejercicios/MultipleOption.module.css'

export function MultipleOptionSolution({ opcion }) {


    return (
        <div>
            <div className={styles.question}>
                {opcion.question}
            </div>
            <div className={styles.answer}>
                {
                    (opcion.answer).map((text, index) => (
                        <>
                            <div style={{color: "black" }}>
                                <input type="checkbox" id={index} 
                                defaultValue={text.value} 
                                defaultChecked={(text.value === 1 && "checked")} />
                                {text.answer}
                            </div>
                            <br />
                        </>
                    ))
                }
            </div>
        </div>
    );
}