import React, { useContext } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import LoginLayout from './pages/Login/login'
import Courses from './pages/Courses'
import Students from './pages/Students'
import Topic from './pages/Topics'
import EnglishInTheRealWorld from './pages/EnglishInTheRealWorld'
import StudentsExercises from './pages/StudentsExercises'
import Exercises from './pages/Exercises'
import Navbar from './components/navbar/Navbar'
import AuthContext from './store/auth-context'
import Testing from './pages/testing/test'
import ExerciseEdit from './components/ejercicios/ExerciseEdit'
import StudentNavBar from './components/navbar/StudentNavBar'
import Exercise from './components/ejercicios/Exercise'

const App = () => {

  const authCtx = useContext(AuthContext)
  const isLoggedIn = authCtx.isLoggedIn
  const isAdmin = authCtx.isAdmin
  return (
    <div>
      {isLoggedIn && isAdmin &&
        <Navbar />
      }
      {isLoggedIn && !isAdmin && <Route path="/student/exercises/">
        <StudentNavBar />
      </Route>
      }
      <Switch>
        {!isLoggedIn && <Route exact path='/'>
          <LoginLayout />
        </Route>
        }
        {isLoggedIn && isAdmin && <Route path='/courses'>
          <Courses />
        </Route>}
        {isLoggedIn && isAdmin && <Route path='/students'>
          <Students />
        </Route>}
        {isLoggedIn && isAdmin && <Route path='/students-exercises/:userId'>
          <StudentsExercises />
        </Route>}
        {isLoggedIn && isAdmin && <Route path='/exercises'>
          <Exercises />
        </Route>}
        {isLoggedIn && isAdmin && <Route path='/topics'>
          <Topic />
        </Route>}
        {/*isLoggedIn && isAdmin && <Route path='/english-real-world'>
          <EnglishInTheRealWorld />
      </Route>*/}
        {isLoggedIn && <Route path='/ejercicios/:theId'>
          <Exercise />
        </Route>}
        {isLoggedIn && isAdmin && <Route path='/ejercicioseditar'>
          <ExerciseEdit />
        </Route>}
        {!isLoggedIn &&
          <Route path='*'>
            <Redirect to='/' />
          </Route>}
        {isLoggedIn && isAdmin &&
          <Route path='*'>
            <Redirect to='/courses' />
          </Route>}
        {isLoggedIn && !isAdmin &&
          <Route path='*'>
            <Redirect to='/student/exercises/my-exercises' />
          </Route>}
      </Switch>
    </div>
  )
}
/*{!isLoggedIn &&<Redirect to='/'/>}
          {isLoggedIn &&<Redirect to='/exercises' exact/>}*/
export default App
