import React from 'react';
import styles from '../../styles/ejercicios/Flechas.module.css'
import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function fisherYatesShuffle(arr) {
  for (var i = arr.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1)); //random index
    [arr[i], arr[j]] = [arr[j], arr[i]]; // swap
  }
}

export function Arrows({ llego, indice, resultado, setResultado, verResolucion, datosResueltos, primeraCarga }) {

  const [tasks, setTasks] = useState(llego);
  const [modelo, setModelo] = useState(llego);

  function rearmarDatos(texto) {

    let agregaId = [];

    for (let index = 0; index < texto.length; index++) {
      let contenido = Object.values(texto[index]);
      agregaId.push({ id: index, question: contenido[0], answer: contenido[1] })
    }

    for (let index = 0; index < texto.length; index++) {
      agregaId[index].id = index + "";
    }

    return agregaId;
  }

  useEffect(() => {

    setModelo(rearmarDatos(llego));
    let ayuda = datosResueltos ? rearmarDatos(datosResueltos) : rearmarDatos(llego)
    !datosResueltos && fisherYatesShuffle(ayuda)
    setTasks(ayuda)

  }, []);





  useEffect(() => {
    let cargar = []

    for (let index = 0; index < modelo.length; index++) {
      cargar.push({ "id": modelo[index].id, "question": modelo[index].question, "answer": tasks[index].answer });
      if (index === (modelo.length - 1)) {
        setResultado( cargar )
      }
    }
  }, [tasks])

  useEffect(()=>{
    if (primeraCarga == indice) {
         let cargar = []

    for (let index = 0; index < modelo.length; index++) {
      cargar.push({ "id": modelo[index].id, "question": modelo[index].question, "answer": tasks[index].answer });
      if (index === (modelo.length - 1)) {
        setResultado(cargar)
      }
    } 
    }

},[primeraCarga])

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div className={styles.contenedor}>
        <DragDropContext onDragEnd={(result) => {
          const { source, destination } = result;
          if (!destination) {
            return;
          }
          if (
            source.index === destination.index &&
            source.droppableId === destination.droppableId
          ) {
            return;
          }
          setTasks((prevTasks) =>
            reorder(prevTasks, source.index, destination.index)
          );

        }}>
          <div className="App">
            <table>
              <tr>
                <td>
                  <table>
                    {modelo.map((task, index) => (
                      <>
                        <tr>
                          <td className={styles.pregunta}>
                            {modelo[index].question}
                          </td>
                          <td>
                            <div className={styles.flechaDivisora}>
                              <img src="arrow.png" alt="->" />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </table>
                </td>
                <td>
                  <Droppable droppableId="tasks">
                    {(droppableProvided) => (
                      <table
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                        className='tasks-container'
                      >
                        {tasks.map((task, index) => (
                          <>
                            <tr>
                              <td >
                                <Draggable key={task.id} draggableId={task.id} index={index}>
                                  {(draggableProvided) => (
                                    <div
                                      {...draggableProvided.draggableProps}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.dragHandleProps}
                                      className={styles.respuesta}
                                    >

                                      <div style={modelo[index].answer == task.answer ? ({ color: "black" }) : (verResolucion ? ({ color: "red" }) : ({ color: "black" }))}>
                                        {task.answer}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              </td>
                            </tr>
                          </>
                        ))}
                        {droppableProvided.placeholder}
                      </table>
                    )}
                  </Droppable>
                </td>
              </tr>
            </table>
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}


