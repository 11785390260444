import React from 'react';
import styles from '../../styles/ejercicios/Text.module.css'

export function Text({ texto }) {
    return (
        <div className={styles.container}>
            <p style={{whiteSpace: 'pre'}}>{texto}</p>
        </div>
    );
}
