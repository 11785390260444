import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  AlertTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material/";
import { useState, useContext, Component } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import FormRegister from "../registrarse/registrarse";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export default function FormLogin() {
  const [body, setBody] = useState({ email: "", password: "" });
  const [error, setError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorShow, setErrorShow] = useState("Check your network connection")
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  let { url, path } = useRouteMatch();

  const handleChange = (e) => {
    setBody({
      ...body,
      [e.target.name]: e.target.value,
    });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = () => {
    if (body.email.length === 0) {

    } else {
      axios
        .post(`https://www.api.simplifiedenglishacademy.com.uy/users/login`, {
          email: body.email,
          password: body.password,
        })
        .then((res) => {
          if (res.status === 200) {
            authCtx.login(res.data);
            const isAdmin = authCtx.isAdmin;
            !isAdmin
              ? history.replace("/student/exercises/my-exercises")
              : history.replace("/courses");
          }
        })
        .catch((error) => {
          setError(true);
          setTimeout(() => {
            setError(false);
            setErrorShow("Check your network connection")
          }, 5000);
          if (error.response.status === 401) {
            setErrorShow("Wrong username or password")
          }

        });
    }
  };

  return (
    <>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={100} classNames="fade">
              <Switch location={location}>

                <Route exact path={'/'}>
                  <Box sx={{
                    width: '400px',
                    height: 'max-contain',
                    minHeight: '400px',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    border: '8px solid #52A0EA',
                    borderRadius: '36px',
                    position: 'relative',
                    zIndex: 1
                  }}>
                    <Typography sx={{
                      fontSize: '30px',
                      color: 'white',
                      lineHeight: '60px',
                      textAlign: 'center',
                      marginX: 'auto',
                      width: '100%',
                      maxWidth: '300px',
                      height: '70px',
                      background: '#52A0EA',
                      borderRadius: '0px 0px 36px 36px'
                    }}>Iniciar Sesión</Typography>
                    <Box sx={{ padding: 5, textAlign: 'center' }}>
                      <TextField
                        name='email'
                        value={body.email}
                        variant='outlined'
                        fullWidth
                        color='info'
                        label="Email"
                        onChange={handleChange} />
                      <TextField
                        name="password"
                        value={body.password}
                        variant="outlined"
                        fullWidth
                        color="info"
                        label="Password"
                        sx={{ marginTop: "1rem" }}
                        onChange={handleChange}
                        type={passwordShown ? "text" : "password"}
                      />
                      <FormControlLabel
                        control={<Checkbox onClick={togglePassword} />}
                        label="Mostrar contraseña"
                      />
                      <Box
                        sx={{
                          minHeight: '60px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <Typography>
                          <Link to={'/'} style={{
                            color: '#000',
                            textDecoration: 'none'
                          }} href="#">
                            ¿Olvidaste la contraseña?
                          </Link>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          ¿No tienes cuenta? <Link to={'/registrarse'}>Registrarse</Link>
                        </Typography>
                      </Box>
                      {error ?
                        <Alert severity="error"><strong>Error! </strong>Usuario/Contraseña invalidos.</Alert>
                        : null
                      }
                      <Button
                        onClick={onSubmit}
                        size={'large'}
                        variant='contained'
                        color='info'
                        sx={{
                          marginTop: '1rem',
                          minWidth: '200px'
                        }}>
                        Ingresar
                      </Button>
                    </Box>
                  </Box>
                </Route>
                <Route path={'/registrarse'} component={FormRegister} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
    </>
  )
}
