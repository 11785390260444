import React from 'react';
import styles from '../../styles/ejercicios/Title.module.css'

export function SendResult({ enviarResultado, verResolucion }) {
    return (
        <div className={styles.container}>
            <div>
                <img src='eric.png' className={styles.boy} />
            </div>
            <table>
                <tr>
                    <td>
                        <div className={styles.balloon}>
                            <h4>
                                {verResolucion ? "Solution successfully submitted." : "If you are sure about the result, press send."}
                            </h4>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td >
                        {!verResolucion && <button onClick={() => enviarResultado()} className={styles.buttonSend}>Send Result</button>}
                    </td>
                </tr>
            </table>
        </div>
    );
}