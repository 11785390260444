import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'

const UploadFile = (props) => {
    const [progress, setProgress] = useState(false);

    function saveFile(e) {
        setProgress(true)
        var file = e.target.files[0] //the file
        var reader = new FileReader() //this for convert to Base64 
        reader.readAsDataURL(e.target.files[0]) //start conversion...
        reader.onload = function (e) { //.. once finished..
            var rawLog = reader.result.split(',')[1]; //extract only thee file data part
            var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
            fetch('https://script.google.com/macros/s/AKfycbyF3vWtDo4LOQ6S7ADD_WzqNjmC3bhJlUzGUS3kiNTK3q8FjVKs/exec', //your AppsScript URL
                { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                .then(res => res.json()).then((a) => {
                    props.parentCallback(a);
                    setProgress(false)
                    props.setCulminarCarga(true)
                })
        }
    }

    return (
        <>
            <input type="file" accept="application/msword, application/pdf, audio/*,  image/* " id="customFile" onChange={(e) => saveFile(e)} />
            {progress &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }
        </>
    )
}

export default UploadFile
