import { Grid, Container, Box } from "@mui/material";
import background from './../../assets/background1.png'
import FormLogin from "../../components/login/formLogin";

export default function LoginLayout () {
    return (
        <Grid sx={{
            background: '#efefef',            
            }}>
        <Container maxWidth='xl' sx={{
            minHeight: '100vh', 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'hidden'}}>
            
            <Container maxWidth="xl"  sx={{
                position: 'fixed',
                zIndex: 0,
                backgroundImage: `url('${background}')`,
                filter: 'blur(5px)',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100%', 
            }}></Container>
            <Box sx={{overflow: 'hidden', maxWidth: '500px', maxHeight: '100vh'}}>
                <FormLogin />
            </Box>
        </Container>
        </Grid>
    )
}