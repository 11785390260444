import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../store/auth-context';
import axios from 'axios';
import FileCard from '../components/estudiante/FileCard';

const StudentTopicToReadView = (type) => {
    const [data, setData] = useState([])

    const authCtx = useContext(AuthContext)
    const token = authCtx.token

    const refresh = authCtx.refreshEdit

    useEffect(() => {
        axios.get('https://www.api.simplifiedenglishacademy.com.uy/topic?type=Read', {
            headers: {
                'Authorization': `${token}`
            }
        })
            .then((res) => {
                setData(res.data.response)
            })
            .catch((error) => {
                console.error(error)
            })

    }, [refresh]);

    return (
        <div>
            {data.map((el, key) => (
                <FileCard key={key} dataCard={el} />
            ))}
        </div>
    )
}

export default StudentTopicToReadView
