import React from 'react';
import styles from '../../styles/ejercicios/Select.module.css'

export function SelectSolution({ text }) {
    const parts = text.split(/\[(.*?)\]/);

    const renderedText = parts.map((part, index) => {
        if (index % 2 === 0) {
            return <span key={index}>{part}</span>;
        } else {
            const options = part.split('/');
            const defaultValue = options[0];
            return (
                <select className={styles.palabras} key={index} defaultValue={defaultValue} disabled>
                    {options.map((option, optionIndex) => (
                        <option className={styles.palabras} key={optionIndex} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            );
        }
    });

    return <div id="contenedor" className={styles.contenedor}>
        <table>
            <tr>
                <td>
                    <div id="texto" className={styles.texto}>
                        {renderedText}
                    </div>
                </td>
            </tr>
        </table>
    </div>;
}
