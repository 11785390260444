import React, { useEffect, useState } from 'react';
import styles from '../../styles/ejercicios/Similary.module.css'

let texto = "";
const separadorInicial = "[";
const separadorFinal = "]";
let iniciaConTexto = true;
let separado = [];
let separadoResultado = []
let armadoFinal = "";

function clasificado(llego) {

    let separadoInterno = [];
    armadoFinal = "";
    texto = llego;

    if (texto[1] != separadorInicial) {
        iniciaConTexto = true;
    } else {
        iniciaConTexto = false;
    }

    let cortadoInicial = texto.split(separadorInicial);
    let primerEspacio = true;

    for (let index = 0; index < cortadoInicial.length; index++) {
        const element = cortadoInicial[index];
        let cortadoFinal = element.split(separadorFinal)

        if (primerEspacio == true) {
            separadoInterno.push({ "tipo": "texto", "contenido": cortadoFinal[0], "id": "" })
        } else {
            let corte = cortadoFinal[0].split("/");
            separadoInterno.push({ "tipo": "campo", "contenido": corte[0], "id": "", "respuesta": corte[1] })
            if (cortadoFinal[1]) {
                separadoInterno.push({ "tipo": "texto", "contenido": cortadoFinal[1], "id": "" })
            }
        }
        primerEspacio = false;
    }

    let lugar = 1;

    for (let index = 0; index < separadoInterno.length; index++) {
        const element = separadoInterno[index];
        if (element.tipo == "campo") {
            separadoInterno[index].id = lugar;
            lugar++;
        }
    }
    return separadoInterno
}

function separoPalabras(palabras) {
    let dividido = palabras.split(" ")
    let rearmado = []
    for (let index = 0; index < dividido.length; index++) {
        rearmado.push(dividido[index]);
    }
    return rearmado
}

export function Similary({ llego, resultado, setResultado, indice, verResolucion, datosResueltos, primeraCarga }) {

    const [selected, setSelected] = useState([]);
    const [generaCambio, setGeneraCambio] = useState(true)
    const [acertados, setAcertados] = useState([])

    useEffect(() => {
        separadoResultado = datosResueltos ? clasificado(datosResueltos) : false
        separado = clasificado(llego);

        if (separadoResultado) {
            separadoResultado.map((separo, lugar) => {
                if (separo.tipo == "campo") {

                    setSelected(
                        oldArray => (
                            {
                                ...oldArray,
                                [separo.id]: separo.respuesta
                            }
                        )
                    )

                }
            }
            )
            setGeneraCambio(!generaCambio)
        }
    }, [])

    const OnChangeSimilary = (event) => {
        setSelected(
            oldArray => (
                {
                    ...oldArray,
                    [event.target.id]: event.target.value
                }
            )
        )
        setGeneraCambio(!generaCambio)
    }

    useEffect(()=>{
        if (primeraCarga == indice) {
            setGeneraCambio(!generaCambio)      
        }
    },[primeraCarga])

    useEffect(() => {

        let cargar = "";


        separado.map((separo, lugar) => {
            if (separo.tipo == "campo") {
                cargar = cargar + "[" + (selected[separo.id] ? (separado[lugar].contenido + "/" + selected[separo.id]) : "") + "]"
                if ((selected[separo.id] && selected[separo.id].toLowerCase()) == (separo.respuesta).toLowerCase()) {
                    setAcertados(
                        oldArray => ({
                            ...oldArray,
                            [separo.id]: true
                        }
                        )
                    )
                } else {
                    setAcertados(
                        oldArray => ({
                            ...oldArray,
                            [separo.id]: false
                        }
                        )
                    )

                }
            }
            separo.tipo == "texto" && (
                cargar = cargar + separo.contenido
            )
            //ahora enviar el dato 
        }
        )

        setTimeout(() => {
        setResultado( cargar )            
        }, 100);

    }, [generaCambio])

    return (
        <div id="contenedor" className={styles.contenedor}>
            <table>
                <tr>
                    <td>
                        {armadoFinal}
                        <div id="texto" className={styles.texto}>
                            {separado.map((separo, index) => (
                                <>
                                    {separo.tipo == "campo" &&
                                        <>
                                            ({separo.id})

                                            <input type="text" placeholder={separo.contenido} className={styles.palabras} id={separo.id} style={(acertados[separo.id] === true) ? ({ color: "black" }) : (verResolucion ? ({ color: "red" }) : ({ color: "black" }))} onChange={(event) => OnChangeSimilary(event)} defaultValue={verResolucion ? (separadoResultado ? separadoResultado[index].respuesta : "") : ""}></input>

                                        </>
                                    }
                                    {separo.tipo == "texto" &&
                                        <>
                                            {
                                                (separoPalabras(separo.contenido)).map((palab) =>
                                                    <div className={styles.textoDividido}>
                                                        {palab}
                                                    </div>
                                                )
                                            }
                                        </>
                                    }
                                </>
                            ))}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div id="contenedorPalabras" className={styles.contenedorPalabras}>
                            {separado.map((separo) => (
                                <>
                                    {separo.tipo == "campo" &&
                                        <>
                                            <div className={styles.movibles}>({separo.id}){separo.contenido}</div>
                                        </>
                                    }
                                </>
                            ))}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}


