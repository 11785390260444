import React, { useState, useContext } from 'react'
import { AppBar, Toolbar, Typography, Tabs, Tab, useMediaQuery, useTheme, Button, makeStyles } from '@material-ui/core'
import DrawerComponent from './Drawer/DrawerComponent';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router'
import AuthContext from '../../store/auth-context';


const useStyles = makeStyles((theme) => ({
    toolbarButtons: {
        marginLeft: 'auto',
    },
}));

export const Navbar = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0)
    const history = useHistory()
    const authCtx = useContext(AuthContext)

    const clickTabHandler = (e, newValue) => {
        setValue(newValue)
    }
    const logOuthandler = (e) => {
        authCtx.logout()
        history.replace('/')
    }
    const theme = useTheme()
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <Typography>
                        Simplified english
                    </Typography>
                    {isMatch ? <DrawerComponent /> : (
                        <>
                            <Tabs onChange={clickTabHandler} indicatorColor='secondary' value={value}>
                                {authCtx.isAdmin && <Tab label='Courses' component={Link} to="/courses" />}
                                {authCtx.isAdmin && <Tab label='Students' component={Link} to="/students" />}
                                {authCtx.isAdmin && <Tab label='Activities' component={Link} to="/exercises" />}
                                {authCtx.isAdmin && <Tab label='Topics' component={Link} to="/topics" />}
                                {/*authCtx.isAdmin && <Tab label='Englis in the real world' component={Link} to="/english-real-world" />*/}

                            </Tabs>
                            <div className={classes.toolbarButtons}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={logOuthandler}
                                >
                                    Log out
                                </Button>
                            </div>
                        </>
                    )}

                </Toolbar>
            </AppBar>
            <Toolbar />
        </div>
    )
}

export default Navbar