import React from 'react';
import styles from '../../styles/ejercicios/Similary.module.css'

function SimilarySolution({ resultado }) {
  const parts = resultado.split(/\[(.*?)\]/);
  const elements = parts.map((part, index) => {
    if (index % 2 === 0) {
      return <span key={index}>{part}</span>;
    } else {
      const inputText = part.split('/')[1];
      return (
        <span className={styles.textoDividido} key={index} style={{ whiteSpace: 'nowrap' }}>
          <input type="text" value={inputText} className={styles.palabras} readOnly />
        </span>
      );
    }
  });

  return (
    <div id="contenedor" className={styles.contenedor}>
      <table>
        <tr>
          <td>
            <div className={styles.texto}>
              {elements}
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default SimilarySolution;