import React from 'react';
import styles from '../../styles/ejercicios/Cloze.module.css'

function ClozeSolution({ resultado }) {
    // Divide el texto en partes según las marcas "[algo]"
    const parts = resultado.split(/\[(.*?)\]/);
    const elements = parts.map((part, index) => {
        // Si el índice es par, mostrar el texto normal
        if (index % 2 === 0) {
            return <span className={styles.texto} key={index}>{part}</span>;
        } else {
            // Obtener el texto entre corchetes y convertirlo a minúsculas
            const wordToComplete = part.toLowerCase();
            return (
                <input
                    className={styles.palabras}
                    key={index}
                    type="text"
                    placeholder={wordToComplete}
                />
            );
        }
    });

    return (
        <div id="contenedor" className={styles.contenedor}>
            <table>
                <tr>
                    <td>
                        <div className={styles.texto}>
                            {elements}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default ClozeSolution;
