import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Card,
  CardContent,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Container,
} from "@mui/material";
import TvIcon from "@mui/icons-material/Tv";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import "../../App.css";
import StudentExerciseView from "../../pages/StudentExerciseView";
import AuthContext from "../../store/auth-context";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import StudentTopicToReadView from "../../pages/StudentTopicToReadView";
import StudentTopicToListenView from "../../pages/StudentTopicToListenView";
import StudentEnglishRealWorldView from "../../pages/StudentEnglishRealWorldView";
import Exercise from "../ejercicios/Exercise";
import { Route, Switch, NavLink, useRouteMatch } from "react-router-dom";
import Chat from "../chat/Chat"

const cardContentHeaderWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "center",
  minHeight: "50px",
};

const cardContentBody = {
  paddingX: 0,
  display: "flex",
  alignItems: "center",
  minHeight: "240px",
};

const glassMorphism = {
  width: "200px",
  minHeight: "388px",
  boxShadow: "none",
};

let iterator = [
  { text: "My Exercises", url: `/my-exercises` },
  { text: "Topics to Read", url: `/my-topics-read` },
  { text: "Topics to Listen", url: `/my-topics-listen` },
  //{ text: "English in the real world", url: `/my-world` },
  { text: "Chat", url: `/my-chat` },
];

const amActive = {
  backgroundColor: "red",
};

let logoutList = {
  margin: "0",
  display: "flex",
  height: "50px",
  width: "200px",
  alignItems: "center",
  padding: "0",
  justifyContent: "center",
};

export default function StudentNavBar() {
  const authCtx = useContext(AuthContext);
  const userName = authCtx.name;
  const { url } = useRouteMatch();
  const history = useHistory();

  const logOuthandler = (e) => {
    authCtx.logout();
    history.replace("/");
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        style={{ position: "static!important" }}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            maxHeight: "100vh",
          }}
        >
          <Card sx={glassMorphism}>
            <CardContent sx={cardContentHeaderWrapper}>
              <Typography variant="body1" component={"div"}>
                Welcome.
              </Typography>
              <Typography variant="h6" component={"div"}>
                {userName}
              </Typography>
            </CardContent>
            <Divider />
            <CardContent sx={cardContentBody}>
              <List disablePadding>
                {iterator.map((el, index) => (
                  <NavLink
                    activeClassName={amActive}
                    to={`${url}${el.url}`}
                    key={index}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton sx={{ width: "200px", paddingX: 0 }}>
                      <ListItemIcon
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "46px",
                          height: "36px",
                          minWidth: "max-content",
                          backgroundColor: "#547AFF",
                          borderRadius: "0 30px 30px 0",
                          color: "white",
                        }}
                      >
                        {index === 0 ? (
                          <TvIcon sx={{ fontSize: "18px" }} />
                        ) : index === 1 ? (
                          <SchoolOutlinedIcon />
                        ) : index === 2 ? (
                          <SchoolOutlinedIcon />
                        ) : (
                          <PersonOutlineOutlinedIcon />
                        )}
                      </ListItemIcon>
                      <ListItem
                        sx={{
                          padding: '0px 0px 0px 15px' ,
                          width: "100%",
                          minWidth: "100px",
                          textAlign: "left",
                        }}
                      >
                        <ListItemText>{el.text}</ListItemText>
                      </ListItem>
                    </ListItemButton>
                  </NavLink>
                ))}
              </List>
            </CardContent>
            <Divider />
            <CardContent
              sx={{
                height: "50px",
                display: "flex",
                width: "100%",
                maxWidth: "200px",
                padding: 0
              }}
            >
              <List sx={logoutList}>
                <ListItemButton
                  sx={{ position: "absolute", bottom: 0 }}
                  disableTouchRipple
                  disableRipple
                >
                  <ListItem
                    sx={{
                      padding: 0,
                      width: "100%",
                      minWidth: "100px",
                      textAlign: "center",
                    }}
                  >
                    <ListItemText
                      onClick={() => {
                        logOuthandler();
                      }}
                    >
                      Log out
                    </ListItemText>
                  </ListItem>
                </ListItemButton>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Drawer>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Simplified English
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth={"xl"} sx={{ marginTop: "1rem" }}>
        <Switch>
          <Route path={`${url}/my-exercises`}>
            <StudentExerciseView />
          </Route>
          <Route path={`${url}/my-topics-read`}>
            <StudentTopicToReadView type="Read" />
          </Route>
          <Route path={`${url}/my-topics-listen`}>
            <StudentTopicToListenView type="Listen" />
          </Route>
          {/*<Route path={`${url}/my-world`}>
            <StudentEnglishRealWorldView />
          </Route>*/}
          <Route path={`${url}/ejercicios/:theId`}>
            <Exercise />
          </Route>
          <Route path={`${url}/my-chat`}>
            <Chat />
          </Route>
        </Switch>
      </Container>
    </Box>
  );
}
